import React from "react";
import { Button, Card } from "react-bootstrap";
import { CardHeader, Grid, Typography, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { toAbsoluteUrl } from "../../_metronic/_helpers/index.js";
import { Categories } from "../data/Categories.js";

const styles = (theme) => ({
	media: {
		height: "300px",
		width: "323px",
	},
	infoRow: {
		display: "flex",
		alignItems: "left",
		paddingTop: 10,
		paddingBottom: 10,
	},
	border: {
		borderBottom: "1px solid #ddd",
	},
	title: {
		fontSize: "1rem",
		fontWeight: 500,
		marginLeft: 10,
		marginRight: 10,
		cursor: "pointer",
		color: "#2196f3",
	},
	icon: {
		color: "white",
		float: "right",
	},
	cardHeader: {
		backgroundColor: "teal",
		color: "white",
	},
});

const InstituteSurveyList = (props) => {
	const { classes } = props;
	const categories = Categories;
	return (
		<>
			<div className="container">
				<Grid
					container
					direction="row"
					justify="flex-start"
					alignItems="stretch"
					style={{ display: "flex" }}
					spacing={10}
				>
					{categories.map((each, i) => (
						<Grid item xs={4} key={i}>
							<Card>
								<CardHeader
									title={<span style={{ color: "white" }}>{each.title}</span>}
									className={classes.cardHeader}
									// avatar={<each.icon className={classes.icon} />}
								/>
								<Grid
									style={{ padding: "0px 10px" }}
									item
									container
									direction="row"
									justify="flex-start"
									alignItems="flex-start"
								>
									{each.subCategories &&
										each.subCategories.map((sub, index) => (
											<Grid
												item
												container
												direction="row"
												justify="space-between"
												alignItems="stretch"
												className={classNames(
													classes.infoRow,
													index !== each.length - 1 && classes.border
												)}
												key={index}
											>
												<Grid item>
													{/* <Link to={sub.route} style={{ textDecoration: "none" }}>
													<Typography className={classes.title}>
														{sub.title}
													</Typography>
												</Link> */}
													<NavLink
														className="menu-link menu-toggle"
														to={"/survey/form/" + each.id + "/" + sub.route}
													>
														<Typography className={classes.title}>
															{sub.title}
														</Typography>
													</NavLink>
												</Grid>
												{/* <Grid item>
												<Typography
													variant="body1"
													style={{ marginRight: "10px" }}
												>
													{getNepaliNumber(this.getCount(sub.route) || 0)}
												</Typography>
											</Grid> */}
											</Grid>
										))}
								</Grid>
							</Card>
						</Grid>
					))}
				</Grid>
				{/* <div className="row">
					{categories.map(function(category, index) {
						return (
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-5" key={index}>
								<Card
									style={{ width: "100%" }}
									className="d-flex flex-column align-items-center justify-content-center"
								>
									<Card.Body>
										<div className="category-img mb-5 mx-auto">
											<SVG
												width={100}
												height={100}
												src={toAbsoluteUrl(category.icon)}
											/>
										</div>
										<div className="category-card-title d-flex justify-content-center">
											<h2 className="text-center mb-5">{category.title}</h2>
										</div>
										<div className="text-center">
											<NavLink
												className="menu-link menu-toggle"
												to={"/survey/form/" + category.id}
											>
												<button className="btn btn-light-primary font-weight-bold px-16 font-size-h6">
													View
												</button>
											</NavLink>
										</div>
									</Card.Body>
								</Card>
							</div>
						);
					})}
				</div> */}
			</div>
		</>
	);
};

export default withStyles(styles)(InstituteSurveyList);
