import React from "react";
import { Form } from "react-bootstrap";
import { CheckBox } from "react-bootstrap";
import {
	adhiyaThekkaList,
	adhiyaThekkaYesQn,
	agriCropKeys,
	agriCropType,
	agriDaalType,
	agriFruitType,
	agriOilType,
	agriProduction,
	agriProductionType,
	agriProductionTypeQuestion,
	agriVegetableType,
	agriYesNo,
	beefishsilkType,
	beefishsilkYes,
	beefishsilkYesNo,
	beefishType,
	beefishYesNo,
	bigha,
	birdDiffType,
	cashCropType,
	dhur,
	elaaniQn,
	forestRegistered,
	forestUseList,
	forestUseYesNo,
	forestUseYesNoQn,
	forestUseYesQn,
	hasHouseMapPass,
	hasHouseMapPassQuestion,
	ifOtherValueQuestion,
	insecticideQuestion,
	insecticideType,
	insecticideYes,
	insecticideYesNo,
	irrigationForLand,
	irrigationForLandList,
	kattha,
	landforAgri,
	landforAgriYesNo,
	landUsageList,
	landUsageQn,
	livestockDiffType,
	livestockQuestion,
	livestockType,
	livestockYes,
	livestockYesNo,
	nambariQn,
	otherLand,
	otherLandArea,
	otherLandAreaBighaKattha,
	ownLand,
	privateForestQn,
	seedNeed,
	seedNeedQuestion,
	totalBeefidhsilkIncome,
	totalFamilyLand,
	totalFamilyLandQn,
} from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import {
	BeeSection,
	BuffalloSection,
	ChickenSection,
	CowSection,
	DuckSection,
	FishSection,
	GadhaSection,
	GaruSection,
	GoatSection,
	HybridChickenSection,
	PigSection,
	RangaSection,
	SheepSection,
	SilkSection,
	YakSection,
} from "../components/LivestockDetail";
import SingleSelectInput from "../components/SingleSelectInput";

const KrishiTathaPashu = (props) => {
	const { formik } = props;
	const { agriLivestock } = formik.values;
	const getLandArea = (section) => (
		<>
			{otherLandAreaBighaKattha.map((units, index) => {
				return (
					<div className="col-lg-6" key={`${section}${index}`}>
						<div class="form-group">
							<label>{units[selectedLanguage]}</label>
							<input
								type="number"
								class="form-control"
								name={`agriLivestock.${section}${units.english}`}
								onChange={formik.handleChange}
								value={agriLivestock[`${section}${units.english}`]}
							/>
						</div>
					</div>
				);
			})}
			<div className="col-lg-6 d-none d-lg-block"></div>
		</>
	);
	const getSelectFor = (section, list, question) => {
		return (
			<div className="col-lg-6">
				<div class="form-group">
					<label>{question[selectedLanguage]}</label>
					<CustomSelectMulti
						formik={formik}
						value={agriLivestock[`${section}`]}
						isMulti={true}
						list={list}
						field={`agriLivestock.${section}`}
					/>
				</div>
			</div>
		);
	};
	const getIncomeExpense = (nepaliQuestion, key) => {
		return (
			<>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{`${nepaliQuestion} उत्पादन वाट बार्षिक आम्दानी कति हो ?`}</label>
						<input
							type="number"
							class="form-control"
							name={`agriLivestock.${key}Income`}
							onChange={formik.handleChange}
							value={agriLivestock[`${key}Income`]}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{`${nepaliQuestion} खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)`}</label>
						<input
							type="number"
							class="form-control"
							name={`agriLivestock.${key}Expense`}
							onChange={formik.handleChange}
							value={agriLivestock[`${key}Expense`]}
						/>
					</div>
				</div>
			</>
		);
	};
	const getAgriProductionSection = (
		list,
		subListKey,
		landKey,
		nepaliSection
	) => {
		const question = {
			nepali: `कुन कुन ${nepaliSection}उत्पादन तथा बिक्रि विवरण छन् ?`,
			english: "",
		};
		return (
			<>
				{list.length && getSelectFor(`${subListKey}Type`, list, question)}

				{agriLivestock[`${subListKey}Type`] == "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{ifOtherValueQuestion[selectedLanguage]}</label>
							<input
								type="text"
								class="form-control"
								name={`agriLivestock.${subListKey}Other`}
								onChange={formik.handleChange}
								value={agriLivestock[`${subListKey}Other`]}
							/>
						</div>
					</div>
				)}
				{getIncomeExpense(nepaliSection, subListKey)}
				{getLandArea(landKey)}
				{agriLivestock[`${subListKey}Type`]?.map((value) => {
					const key = list.find((i) => i.value === value);
					const apiKey = key.english.replace(/\s+/g, "");
					return (
						<>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{`${key[selectedLanguage]} खेति उत्पादन के. जि`}</label>
									<input
										type="number"
										class="form-control"
										name={`agriLivestock.agri${apiKey}ProductionKg`}
										onChange={formik.handleChange}
										value={agriLivestock[`agri${apiKey}ProductionKg`]}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div class="form-group">
									<label>{`${key[selectedLanguage]} खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)`}</label>
									<input
										type="number"
										class="form-control"
										name={`agriLivestock.agri${apiKey}SaleKg`}
										onChange={formik.handleChange}
										value={agriLivestock[`agri${apiKey}SaleKg`]}
									/>
								</div>
							</div>
						</>
					);
				})}
			</>
		);
	};
	const getAgriProductionDetails = () => {
		return (
			<>
				{agriLivestock.agriProductionType?.includes("crops") &&
					getAgriProductionSection(
						agriCropType,
						"agriCrop",
						"crops",
						agriProductionType.find((i) => i.value == "crops")?.nepali
					)}
				{agriLivestock.agriProductionType?.includes("oilseed") &&
					getAgriProductionSection(
						agriOilType,
						"agriOil",
						"oil",
						agriProductionType.find((i) => i.value == "oilseed")?.nepali
					)}
				{agriLivestock.agriProductionType?.includes("daal") &&
					getAgriProductionSection(
						agriDaalType,
						"agriDaa",
						"daal",
						agriProductionType.find((i) => i.value == "daal")?.nepali
					)}
				{agriLivestock.agriProductionType?.includes("vegetable") &&
					getAgriProductionSection(
						agriVegetableType,
						"agriVegetable",
						"vegetable",
						agriProductionType.find((i) => i.value == "vegetable")?.nepali
					)}
				{agriLivestock.agriProductionType?.includes("fruit") &&
					getAgriProductionSection(
						agriFruitType,
						"agriFruit",
						"fruit",
						agriProductionType.find((i) => i.value == "crops")?.nepali
					)}
				{agriLivestock.agriProductionType?.includes("cash_crops") &&
					getAgriProductionSection(
						cashCropType,
						"agriCashCrop",
						"cashCrop",
						agriProductionType.find((i) => i.value == "cash_crops")?.nepali
					)}
				{agriLivestock.agriProductionType?.includes("flower") &&
					getAgriProductionSection(
						[],
						"agriFlower",
						"flower",
						agriProductionType.find((i) => i.value == "flower")?.nepali
					)}
			</>
		);
	};
	const getAnimalSection = () => {
		const question = {
			nepali: `कुन कुन पशु पाल्नु भएको छ ?`,
			english: "",
		};
		const section = "livestockDiffType";
		return (
			<>
				{getSelectFor(section, livestockDiffType, question)}
				{agriLivestock.livestockDiffType?.includes("other") && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{ifOtherValueQuestion[selectedLanguage]}</label>
							<input
								type="text"
								class="form-control"
								name={`agriLivestock.livestockOther`}
								onChange={formik.handleChange}
								value={agriLivestock[`livestockOther`]}
							/>
						</div>
					</div>
				)}
				{agriLivestock.livestockDiffType?.includes("cow") && (
					<CowSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("buffallo") && (
					<BuffalloSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("goat/boka") && (
					<GoatSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("ranga") && (
					<RangaSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("pig") && (
					<PigSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("sheep") && (
					<SheepSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("garu") && (
					<GaruSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("gadha") && (
					<GadhaSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.livestockDiffType?.includes("yak") && (
					<YakSection formik={formik} agriLivestock={agriLivestock} />
				)}
			</>
		);
	};
	const getBirdSection = () => {
		const question = {
			nepali: `कुन कुन पन्छी पाल्नु भएको छ ?`,
			english: "",
		};
		const section = "birdDiffType";
		return (
			<>
				{getSelectFor(section, birdDiffType, question)}
				{agriLivestock.birdDiffType?.includes("other") && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{ifOtherValueQuestion[selectedLanguage]}</label>
							<input
								type="text"
								class="form-control"
								name={`agriLivestock.birdOther`}
								onChange={formik.handleChange}
								value={agriLivestock[`birdOther`]}
							/>
						</div>
					</div>
				)}
				{agriLivestock.birdDiffType?.includes("chicken") && (
					<ChickenSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.birdDiffType?.includes("hybrid") && (
					<HybridChickenSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.birdDiffType?.includes("pegion") && (
					<DuckSection formik={formik} agriLivestock={agriLivestock} />
				)}
			</>
		);
	};
	const getLiveStockSection = () => {
		return (
			<>
				{agriLivestock.livestockType?.includes("animal") && getAnimalSection()}
				{agriLivestock.livestockType?.includes("bird") && getBirdSection()}
			</>
		);
	};
	const getBeeFishSilkSection = () => {
		return (
			<>
				{agriLivestock.beefishsilkType?.includes("fish") && (
					<FishSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.beefishsilkType?.includes("bee") && (
					<BeeSection formik={formik} agriLivestock={agriLivestock} />
				)}
				{agriLivestock.beefishsilkType?.includes("resham") && (
					<SilkSection formik={formik} agriLivestock={agriLivestock} />
				)}

				<div className="col-lg-6">
					<div class="form-group">
						<label>{totalBeefidhsilkIncome[selectedLanguage]}</label>
						<input
							type="number"
							class="form-control"
							name={`agriLivestock.totalBeefishsilkIncome`}
							onChange={formik.handleChange}
							value={agriLivestock.totalBeefishsilkIncome}
						/>
					</div>
				</div>
			</>
		);
	};
	return (
		<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
			<div class="col-xl-12 col-xxl-8">
				<div
					class="pb-5"
					data-wizard-type="step-content"
					data-wizard-state="current"
				>
					<h3 class="mb-10 font-weight-bold text-dark">कृषी तथा पशु</h3>
					<div className="row align-items-end">
						<div className="col-12">
							<div class="form-group">
								<label>{totalFamilyLandQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{totalFamilyLand.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="checkbox"
												name={`agriLivestock.totalLandArea`}
												value={value.value}
												onChange={formik.handleChange}
												checked={agriLivestock.totalLandArea?.includes(
													value.value
												)}
											/>
											<span></span> {value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{agriLivestock.totalLandArea?.includes("elaani") && (
							<>
								<div className="col-12">
									<h3 class="mb-10 font-weight-bold text-dark">
										{elaaniQn[selectedLanguage]}
									</h3>
								</div>

								{getLandArea("yelani")}
							</>
						)}
						{agriLivestock.totalLandArea?.includes("nambari") && (
							<>
								<div className="col-12">
									<h3 class="mb-10 font-weight-bold text-dark">
										{nambariQn[selectedLanguage]}
									</h3>
								</div>
								{getLandArea("nambari")}
							</>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{landUsageQn[selectedLanguage]}</label>
								<SingleSelectInput
									id="landUsage"
									name={`agriLivestock.landUsage`}
									value={agriLivestock[`landUsage`]}
									formik={formik}
									selectList={landUsageList}
								/>
							</div>
						</div>
						{[
							"chapter",
							"contract",
							"self_chapter",
							"self_contract",
							"chapter_contract",
							"all",
						].includes(agriLivestock.landUsage) && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>{adhiyaThekkaYesQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="adhiyaThekka"
											name={`agriLivestock.adhiyaThekka`}
											value={agriLivestock[`adhiyaThekka`]}
											formik={formik}
											selectList={adhiyaThekkaList}
										/>
									</div>
								</div>
							)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{forestUseYesNoQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{forestUseYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`agriLivestock.forestUseYesNo`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													agriLivestock.forestUseYesNo === value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{agriLivestock.forestUseYesNo == "forest_yes" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{forestUseYesQn[selectedLanguage]}</label>
										<div class="radio-inline">
											{forestRegistered.map((value, i) => (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`agriLivestock.forestRegistered`}
														defaultChecked={
															agriLivestock.forestRegistered == value.value
														}
														value={value.value}
														onChange={formik.handleChange}
														defaultChecked={
															agriLivestock.forestRegistered === value.value
														}
													/>
													<span></span>
													{value[selectedLanguage]}
												</label>
											))}
										</div>
									</div>
								</div>
								{agriLivestock.forestRegistered == "registered_yes" && (
									<>
										<div className="col-12">
											<h3 class="mb-10 font-weight-bold text-dark">
												{privateForestQn[selectedLanguage]}
											</h3>
										</div>

										{getLandArea("privateForest")}
									</>
								)}
							</>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{landforAgri[selectedLanguage]}</label>
								<div class="radio-inline">
									{landforAgriYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`agriLivestock.landForAgriYesNo`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													agriLivestock.landForAgriYesNo === value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{agriLivestock.landForAgriYesNo == "land_used_yes" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{irrigationForLand[selectedLanguage]}</label>
										<SingleSelectInput
											id="irrigationForLandList"
											name={`agriLivestock.irrigationForLandList`}
											value={agriLivestock[`irrigationForLandList`]}
											formik={formik}
											selectList={irrigationForLandList}
										/>
									</div>
								</div>
								{agriLivestock.irrigationForLandList == "other" && (
									<div className="col-lg-6">
										<div class="form-group">
											<label>{ifOtherValueQuestion[selectedLanguage]}</label>
											<input
												type="text"
												class="form-control"
												name="agriLivestock.irrigationForLandListOther"
												onChange={formik.handleChange}
												value={agriLivestock.irrigationForLandListOther}
											/>
										</div>
									</div>
								)}
								<div className="col-12">
									<h3 class="mb-10 font-weight-bold text-dark">
										{ownLand[selectedLanguage]}
									</h3>
								</div>
								{getLandArea("agriOwnTotal")}

								<div className="col-12">
									<h3 class="mb-10 font-weight-bold text-dark">
										{otherLand[selectedLanguage]}
									</h3>
								</div>
								{getLandArea("agriOtherTotal")}
							</>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{agriProduction[selectedLanguage]}</label>
								<div class="radio-inline">
									{agriYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`agriLivestock.agriYesNo`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={agriLivestock.agriYesNo === value.value}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{agriLivestock.agriYesNo == "agri_sales_yes" &&
							getSelectFor(
								"agriProductionType",
								agriProductionType,
								agriProductionTypeQuestion
							)}
						{getAgriProductionDetails()}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{insecticideQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{insecticideYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`agriLivestock.insecticideYesNo`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													agriLivestock.insecticideYesNo === value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{agriLivestock.insecticideYesNo == "chemical_yes" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{insecticideYes[selectedLanguage]}</label>
										<SingleSelectInput
											id="insecticideType"
											name={`agriLivestock.insecticideType`}
											value={agriLivestock[`insecticideType`]}
											formik={formik}
											selectList={insecticideType}
										/>
									</div>
								</div>
							</>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{livestockQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{livestockYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`agriLivestock.livestockYesNo`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													agriLivestock.livestockYesNo === value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{agriLivestock.livestockYesNo === "yes" && (
							<>
								<div className="col-12">
									<div class="form-group">
										<label>{livestockYes[selectedLanguage]}</label>
										<div class="radio-inline">
											{livestockType.map((value, i) => (
												<label class="radio radio-rounded">
													<input
														type="checkbox"
														name={`agriLivestock.livestockType`}
														checked={agriLivestock.livestockType?.includes(
															value.value
														)}
														value={value.value}
														onChange={formik.handleChange}
													/>
													<span></span> {value[selectedLanguage]}
												</label>
											))}
										</div>
									</div>
								</div>

								{getLiveStockSection()}
							</>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{beefishYesNo[selectedLanguage]}</label>
								<div class="radio-inline">
									{beefishsilkYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`agriLivestock.beefishsilkYesNo`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													agriLivestock.beefishsilkYesNo === value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{agriLivestock.beefishsilkYesNo === "yes" && (
							<>
								<div className="col-12">
									<div class="form-group">
										<label>{beefishsilkYes[selectedLanguage]}</label>
										<div class="radio-inline">
											{beefishsilkType.map((value, i) => (
												<label class="radio radio-rounded">
													<input
														type="checkbox"
														name={`agriLivestock.beefishsilkType`}
														checked={agriLivestock.beefishsilkType?.includes(
															value.value
														)}
														value={value.value}
														onChange={formik.handleChange}
													/>
													<span></span> {value[selectedLanguage]}
												</label>
											))}
										</div>
									</div>
								</div>

								{getBeeFishSilkSection()}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default KrishiTathaPashu;
