import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import ImageUploader from "../components/ImageUploader";
import { ward } from "../data/institute";

const Covid = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							ward: "",
							infectedCount: "",
							uniqueIdentifier: "",
							tested: "",
							homeIsolationCount: "",
							hospitalIsolationCount: "",
							recoverCount: "",
							deadCount: "",
							isolationCenter: "",
							quarentineCenter: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.clubType) {
				//     errors.clubType = 'Required';
				// }
				// if (!values.establishedAt) {
				//     errors.establishedAt = 'Required';
				// }
				// if (!values.memberCount) {
				//     errors.memberCount = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.clubUpdate) {
				//     errors.clubUpdate = 'Required';
				// }
				// if (!values.clubMainWork) {
				//     errors.clubMainWork = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// else
				// if (
				//     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				// ) {
				//     errors.email = 'Invalid email address';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
                                <div className="col-md-6">
										<label className="form-control-label">वडा नम्बर </label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											<option>कुनै छान्नुहोस</option>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">संक्रमित संख्या</label>
										<input
											type="text"
											className="form-control"
											placeholder="संक्रमित संख्या"
											name="infectedCount"
											onChange={handleChange}
											value={values.infectedCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="infectedCount"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">परिक्षण गरिएको</label>
										<input
											type="text"
											className="form-control"
											placeholder="परिक्षण गरिएको"
											name="tested"
											onChange={handleChange}
											value={values.tested}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="tested"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">Home Isolation संख्या</label>
										<input
											type="text"
											className="form-control"
											placeholder="Home Isolation संख्या"
											name="homeIsolationCount"
											onChange={handleChange}
											value={values.homeIsolationCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="homeIsolationCount"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
                                    <div className="col-md-6">
										<label className="form-control-label">Hospital Isolation संख्या</label>
										<input
											type="text"
											className="form-control"
											placeholder="Hospital Isolation संख्या"
											name="hospitalIsolationCount"
											onChange={handleChange}
											value={values.hospitalIsolationCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="hospitalIsolationCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">Recover संख्या</label>
										<input
											type="text"
											className="form-control"
											placeholder="Recover संख्या"
											name="recoverCount"
											onChange={handleChange}
											value={values.recoverCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="recoverCount"
											component="div"
										/>
									</div>
								</div>
                                <div className="form-group row">
                                    <div className="col-md-6">
										<label className="form-control-label">मृत्यु संख्या</label>
										<input
											type="text"
											className="form-control"
											placeholder="मृत्यु संख्या"
											name="deadCount"
											onChange={handleChange}
											value={values.deadCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="deadCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">Isolation Center</label>
										<input
											type="text"
											className="form-control"
											placeholder="Isolation Center"
											name="isolationCenter"
											onChange={handleChange}
											value={values.isolationCenter}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="isolationCenter"
											component="div"
										/>
									</div>
								</div>
                                <div className="form-group row">
                                    <div className="col-md-6">
										<label className="form-control-label">क्वारेन्टाइन केन्द्र</label>
										<input
											type="text"
											className="form-control"
											placeholder="क्वारेन्टाइन केन्द्र"
											name="quarentineCenter"
											onChange={handleChange}
											value={values.quarentineCenter}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="quarentineCenter"
											component="div"
										/>
									</div>
								</div>
								{/* <div className="form-group row">
									<div className="col-md-6">
										<label>फोटो</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div> */}
								<hr />
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>

				// <Form>
				//     <Field type="area" name="area" />
				//     <ErrorMessage className="invalid-feedback" name="area" component="div" />
				//     <Field type="password" name="password" />
				//     <ErrorMessage className="invalid-feedback" name="password" component="div" />
				//     <button type="submit" disabled={isSubmitting}>
				//         Submit
				//     </button>
				// </Form>
			)}
		</Formik>
	</div>
);

export default Covid;
