import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage, useFormik } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";
import {
	add,
	wardQuestion,
	photo,
	address,
	riskArea,
	male,
	skill,
	female,
	effectFromDisasterQn,
	manpowers,
	effectofDeath,
	effectofInjured,
	remove,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";

const initData = {
	data: {
		ward: "",
		manpower: [{ skill: "", male: 0, female: 0 }],
		uniqueIdentifier: GetUUID(),
	}
};

const SkilledDetail = (props) => {
	const [apiData, setApiData] = useState({})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		}
	})
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						manpower: props.data.manpower?.length ? props.data.manpower : [{ skill: "", male: 0, female: 0 }],
					}
				}
			})
		}
		else {
			setApiData(initData)
		}
		console.log(props)
	}, [props])
	const addFormRow = () => {
		// console.log("state val", this.state);
		const prevmanpower = [...formik.values.manpower, { skill: "", male: 0, female: 0 }];
		// prevmanpower.push();
		formik.setValues({
			...formik.values,
			manpower: prevmanpower,
		})
	};
	const deleteFormRow = (index) => {
		const prevmanpower = formik.values.manpower;
		prevmanpower.splice(index, 1);
		formik.setValues({
			...formik.values,
			manpower: prevmanpower,
		})
	};
	const getFormRowList = () => {
		return formik.values?.manpower.map((row, i) => (
			<div className="form-group row" key={i}>
				<div className="col-lg-10">
					<div className="form-group row align-items-center">
						<div className="col-md-3">
							<label>{skill[selectedLanguage]}</label>
							<select
								className="form-control"
								id="ward"
								name={`manpower[${i}].skill`}
								onChange={formik.handleChange}
								value={formik.values?.manpower[i].skill}
							>
								{manpowers.map((level) => (
									<option value={level.value}>{level[selectedLanguage]}</option>
								))}
							</select>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{male[selectedLanguage]}</label>
							<input
								type="number"
								name={`manpower[${i}].male`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.manpower[i].male}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{female[selectedLanguage]}</label>
							<input
								type="number"
								name={`manpower[${i}].female`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.manpower[i].female}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<a
								href="javascript:;"
								onClick={(e) => deleteFormRow(i)}
								className="btn btn-sm font-weight-bolder btn-light-danger"
							>
								<i className="la la-trash-o"></i>
								{cancel[selectedLanguage]}
							</a>
						</div>
					</div>
				</div>
			</div>
		));
	};
	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{wardQuestion[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={formik.values?.ward}
							>
								{ward.map((woda) => (
									<option value={woda.value}>
										{woda[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
						{/* <div className="col-md-6">
							<label>{photo[selectedLanguage]}</label>
							<ImageUploader fileListContent={props.fileListContent} />
						</div> */}
					</div>
					<div>
						<hr />
						{getFormRowList()}
						<div className="form-group row">
							<div className="col-lg-4">
								<a
									href="javascript:;"
									onClick={addFormRow}
									className="btn btn-sm font-weight-bolder btn-light-primary"
								>
									<i className="la la-plus"></i>
									{add[selectedLanguage]}
								</a>
							</div>
						</div>
					</div>

					<div className="card-footer text-right">
						<FormFooterButtons />
					</div>
				</Card.Body>
			</Card>
		</form>
	);
}

export default SkilledDetail;
