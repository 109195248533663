import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	wardQuestion,
	photo,
	employment,
	memberTitle,
	name,
	male,
	amountQn,
	female,
	contact,
	selectedLanguage,
	ward,
} from "../data/institute";

const CooperativeInstitue = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							ward: "",
							name: "",
							contact: "",
							instituteType: "",
							female: "",
							male: "",
							dartaNumber: "",
							dartaMiti: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.contact) {
				//     errors.contact = 'Required';
				// }
				// if (!values.amount) {
				//     errors.amount = 'Required';
				// }
				// if (!values.female) {
				//     errors.female = 'Required';
				// }
				// if (!values.male) {
				//     errors.male = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.jmale) {
				//     errors.jmale = 'Required';
				// }
				// if (!values.jfemale) {
				//     errors.jfemale = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											<option selected disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											सँस्थाको प्रकार
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="सँस्थाको प्रकार"
											name="instituteType"
											onChange={handleChange}
											value={values.instituteType}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="instituteType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">दर्ता नम्बर</label>
										<input
											type="text"
											className="form-control"
											placeholder="दर्ता नम्बर"
											name="dartaNumber"
											onChange={handleChange}
											value={values.dartaNumber}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="dartaNumber"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">दर्ता मिति</label>
										<input
											type="date"
											className="form-control"
											placeholder="दर्ता मिति"
											name="dartaMiti"
											onChange={handleChange}
											value={values.dartaMiti}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="dartaMiti"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>{memberTitle[selectedLanguage]}</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{male[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={male[selectedLanguage]}
											name="male"
											onChange={handleChange}
											value={values.male}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="male"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{female[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={female[selectedLanguage]}
											name="female"
											onChange={handleChange}
											value={values.female}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="female"
											component="div"
										/>
									</div>
								</div>
								<hr />
								{/* <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label" >अन्य</label>
                                            <input type="text" className="form-control" placeholder="अन्य" name="others" onChange={handleChange} value={values.others} />
                                            <ErrorMessage className="invalid-feedback" name="others" component="div" />
                                        </div>
                                    </div> */}
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{contact[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={contact[selectedLanguage]}
											name="contact"
											onChange={handleChange}
											value={values.contact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="contact"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default CooperativeInstitue;
