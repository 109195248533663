import React, { useState } from 'react'
import { useEffect } from 'react';
import CustomSelectMulti from '../components/CustomSelectMulti';
import SingleSelectInput from '../components/SingleSelectInput';
import { agriExpenseQn, agriIncomeQn, covidEffect, covidEffectQn, dhukutiKarobar, dhukutiKarobarYesNo, disputeResolve, disputeResolveQn, electricityUsage, electricityUsageQn, electricityUsageYesNo, electricityUsageYesQn, familyInLocalOrganQuestion, familyInOrg, familyInOrgQn, familyInvolvementLocalOrgan, familyLocalGovInvolve, familyLocalGovInvolveQuestion, foodForMonth, foodForMonthQuestion, ifOtherValueQuestion, loan, loanQuestion, loanTakenInFamily, loanTakenInFamilyQuestion, loanTakingReason, loanTakingReasonQuestion, mainBusiness, mainBusinessQn, mainIncomeSource, mainIncomeSourceQuestion, mainInvestment, mainInvestmentQn, newsMedium, newsMediumQn, nonAgriExpenseQn, nonAgriIncomeQn, suggestion, suggestionQn, unhealthyTreatment, unhealthyTreatmentQn } from '../data/houseSurvey';
import { selectedLanguage } from '../data/institute';

const SamajikForm = (props) => {
    useEffect(() => {
    }, [])
    console.log("form props", props)
    const { formik } = props;
    const { socialWelfare } = formik.values;
    return (
        <>
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-8">
                    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                        <h3 class="mb-10 font-weight-bold text-dark">सामाजिक विवरण</h3>
                        <div className="row">
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{unhealthyTreatmentQn[selectedLanguage]}</label>
                                    <SingleSelectInput
                                        id="unhealthyTreatment"
                                        name="socialWelfare.unhealthyTreatment"
                                        value={socialWelfare.unhealthyTreatment}
                                        formik={formik}
                                        selectList={unhealthyTreatment}
                                    />
                                </div>
                            </div>

                            {
                                socialWelfare[`unhealthyTreatment`] === "others_ill" && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                                            <input type="text" class="form-control" name={`socialWelfare.unhealthyTreatmentOther`} onChange={formik.handleChange} value={socialWelfare[`unhealthyTreatmentOther`]} />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{familyInOrgQn[selectedLanguage]}</label>
                                    <CustomSelectMulti formik={formik} value={socialWelfare[`familyInOrg`]} isMulti={true} list={familyInOrg} field={`socialWelfare.familyInOrg`} />
                                </div>
                            </div>
                            {
                                socialWelfare[`familyInOrg`]?.includes("others_club") && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                                            <input type="text" class="form-control" name={`socialWelfare.familyInOrgOther`} onChange={formik.handleChange} value={socialWelfare[`familyInOrgOther`]} />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{disputeResolveQn[selectedLanguage]}</label>
                                    <SingleSelectInput
                                        id="disputeResolve"
                                        name="socialWelfare.disputeResolve"
                                        value={socialWelfare.disputeResolve}
                                        formik={formik}
                                        selectList={disputeResolve}
                                    />
                                </div>
                            </div>
                            {
                                socialWelfare[`disputeResolve`] === "other_complain" && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                                            <input type="text" class="form-control" name={`socialWelfare.disputeResolveOther`} onChange={formik.handleChange} value={socialWelfare[`disputeResolveOther`]} />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{covidEffectQn[selectedLanguage]}</label>
                                    <SingleSelectInput
                                        id="covidEffect"
                                        name="socialWelfare.covidEffect"
                                        value={socialWelfare.covidEffect}
                                        formik={formik}
                                        selectList={covidEffect}
                                    />
                                </div>
                            </div>

                            {
                                socialWelfare[`covidEffect`] === "others" && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                                            <input type="text" class="form-control" name={`socialWelfare.covidEffectOther`} onChange={formik.handleChange} value={socialWelfare[`covidEffectOther`]} />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{suggestionQn[selectedLanguage]}</label>
                                    <input type="text" class="form-control form-control-solid form-control-lg" onChange={formik.handleChange} name="socialWelfare.suggestion" value={socialWelfare.suggestion} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{newsMediumQn[selectedLanguage]}</label>
                                    <CustomSelectMulti formik={formik} value={socialWelfare[`newsMedium`]} isMulti={true} list={newsMedium} field={`socialWelfare.newsMedium`} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}

export default SamajikForm;