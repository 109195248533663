import { Button, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { data } from "jquery";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { genderList } from "../../data/advanceSearch";
import { selectedLanguage } from "../../data/institute";
import {
	fetchEmergencyDetails,
	saveEmergencyDetails,
} from "../../modules/Auth/_redux/authCrud";
import Loader from "../Loader";
import * as Yup from "yup";

const defaultValues = {
	name: "",
	services: "",
	contactNumber: "",
	latitude: "",
	longitude: "",
};
const EditEmergencySchema = Yup.object().shape({
	name: Yup.string().required("नाम अनिवार्य छ"),
	contactNumber: Yup.string().required("सम्पर्क अनिवार्य छ"),
});
const EditEmergency = (props) => {
	const [userIdToEdit, SetuserIdToEdit] = useState("");
	const [initialValues, setInitialValues] = useState(defaultValues);
	const [loading, setLoading] = useState(true);
	const { authToken } = useSelector(
		({ auth }) => ({
			authToken: auth?.token,
		}),
		shallowEqual
	);
	// useEffect(() => {
	// 	console.log("propsData", props);
	// 	if (!props.id) {
	// 		let token = authToken?.split("Bearer: ")[0];
	// 		if (token) {
	// 			let decodedToken = jwtDecode(token);
	// 			SetuserIdToEdit(decodedToken.id);
	// 		}
	// 	} else {
	// 		SetuserIdToEdit(props.id);
	// 	}
	// }, []);
	useEffect(() => {
		if (props.show) {
			if (!props.id) {
				let token = authToken?.split("Bearer: ")[0];
				if (token) {
					let decodedToken = jwtDecode(token);
					SetuserIdToEdit(decodedToken.id);
				}
			} else {
				SetuserIdToEdit(props.id);
			}

			setLoading(true);
			fetchEmergencyDetails(props.id)
				.then((res) => {
					setLoading(false);
					const data = res.data;
					const userDetails = {
						name: data.name,
						services: data.services,
						id: userIdToEdit,
						contactNumber: data.contactNumber,
						latitude: data.gps ? data.gps.latitude : "",
						longitude: data.gps ? data.gps.longitude : "",
					};
					setInitialValues(userDetails);
				})
				.catch((err) => {
					setLoading(false);
					toast.error("An error occured while fetching user data");
				});
		}
	}, [userIdToEdit, props.show]);
	const formik = useFormik({
		initialValues,
		validationSchema: EditEmergencySchema,
		enableReinitialize: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setSubmitting(true);
			saveEmergencyDetails(values)
				.then((res) => {
					setSubmitting(false);
					if (res.data.success) {
						toast.success("User details updated successfully");
					} else {
						if (res.data) {
							Object.keys(res.data).map((key) => {
								toast.error(res.data[key]);
							});
						}
					}
				})
				.catch((err) => {
					console.log(err.response);
					setSubmitting(false);
					if (err.response.data) {
						Object.keys(err.response.data).map((key) => {
							toast.error(err.response.data[key]);
						});
					}
				});
		},
	});
	const handleClose = () => props.handleDeleteClose();
	const getContent = () => {
		const emergency = formik.values;
		return (
			<>
				{loading && <Loader loading={loading} />}
				{!loading && (
					<form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>संस्थाको नाम: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="name"
										value={emergency.name}
									/>
									{formik.touched.name && formik.errors.name ? (
										<div class="invalid-feedback">{formik.errors.name}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>सेवा: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="services"
										value={emergency.services}
									/>
									{formik.touched.services && formik.errors.services ? (
										<div class="invalid-feedback">{formik.errors.services}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div className="form-group">
									<label>सम्पर्क: </label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="contactNumber"
										value={emergency.contactNumber}
									/>
									{formik.touched.contactNumber &&
									formik.errors.contactNumber ? (
										<div class="invalid-feedback">
											{formik.errors.contactNumber}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6 mb-4">
								<div class="form-group">
									<label>अक्षांश</label>
									<input
										type="text"
										className="form-control mr-3"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										name="latitude"
										value={emergency.latitude}
									/>
									{formik.touched.latitude && formik.errors.latitude ? (
										<div class="invalid-feedback">{formik.errors.latitude}</div>
									) : null}
								</div>
							</div>

							<div className="col-md-6 mb-4">
								<label className="form-group">देशान्तर</label>
								<input
									type="text"
									className="form-control mr-3"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									name="longitude"
									value={emergency.longitude}
								/>
								{formik.touched.longitude && formik.errors.longitude ? (
									<div class="invalid-feedback">{formik.errors.longitude}</div>
								) : null}
							</div>
							<div className="col-12 d-flex justify-content-end">
								<Button
									variant="light-primary"
									className="mr-3"
									type="submit"
									disabled={formik.isSubmitting}
								>
									{formik.isSubmitting ? (
										<Spinner
											animation="grow"
											variant="primary"
											size="sm"
											className="mr-2"
										/>
									) : null}
									सच्यानुहोस
								</Button>
								<Button variant="light-danger" onClick={handleClose}>
									रद्द गर्नुहोस
								</Button>
							</div>
						</div>
					</form>
				)}
			</>
		);
	};
	return (
		<>
			<Modal
				centered
				show={props.show}
				onHide={handleClose}
				scrollable={true}
				dialogClassName="data-detail-modal"
				size="xl"
				style={{ zIndex: "15000" }}
			>
				<Modal.Header closeButton={true}>
					<Modal.Title>आपतकालीन सेवा प्रदायक सम्पर्क थप्नुहोस्</Modal.Title>
				</Modal.Header>
				<Modal.Body>{getContent()}</Modal.Body>
			</Modal>
		</>
	);
};

export default EditEmergency;
