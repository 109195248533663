import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import {
	hospitalType,
	healthInfo,
	vaccineList,
	selectedLanguage,
	healthFacility,
	add,
	cancel,
	ward,
} from "../data/institute";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";
import ImageUploader from "../components/ImageUploader";
const initData = {
	data: {
		uniqueIdentifier: GetUUID(),
		previewVisible: false,
		previewImage: "",
		loaded: 0,
		// other
		name: "",
		ward: "",
		address: "",
		shayaCount: "",
		healthType: "",
		healthFacility: [],
		maxHealthsIndex: [1],
		maxYearIndex: 1,
		year: "",
		healths: [{ healthInfo: "", darbandi: "", count: 0 }],
	},
};
const Hospital = (props) => {
	const [apiData, setApiData] = useState({});
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		},
	});

	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						healths: props.data.healths?.length
							? props.data.healths
							: [[{ healthInfo: "", darbandi: "", count: 0 }]],
					},
				};
			});
		} else {
			setApiData(initData);
		}
	}, [props]);

	const addFormRow = () => {
		const prevhealthsCount = formik.values?.healths;
		prevhealthsCount.push({ healthInfo: "", darbandi: "", count: 0 });
		formik.setValues({
			...formik.values,
			healths: prevhealthsCount,
		});
	};
	const deleteFormRow = (index) => {
		const prevhealthsCount = formik.values?.healths;
		prevhealthsCount.splice(index, 1);
		formik.setValues({
			...formik.values,
			healths: prevhealthsCount,
		});
	};

	const getHealthCountFormSection = () => {
		return formik.values?.healths.map((row, i) => (
			<div className="form-group row" key={i}>
				<div className="col-lg-12">
					<div className="form-group row align-items-center">
						<div className="col-md-3">
							<label>विवरण</label>
							<select
								className="form-control"
								id="healthInfo"
								name={`healths[${i}].healthInfo`}
								onChange={formik.handleChange}
								value={
									formik.values.healths &&
									formik.values.healths[i] &&
									formik.values.healths[i].healthInfo
										? formik.values.healths[i].healthInfo
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{healthInfo.map((info) => (
									<option value={info.value}>{info[selectedLanguage]}</option>
								))}
							</select>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-2">
							<label>ढरबन्दी</label>
							<input
								type="number"
								name={`healths[${i}].darbandi`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.healths[i].darbandi}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-2">
							<label>संख्या</label>
							<input
								type="number"
								name={`healths[${i}].count`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.healths[i].count}
							/>
							<div className="d-md-none mb-2"></div>
						</div>

						<div style={{ "margin-top": "30px" }} className="col-md-2">
							<a
								href="javascript:;"
								onClick={addFormRow}
								className="btn btn-sm font-weight-bolder btn-light-primary"
							>
								<i className="la la-plus"></i>
								{add[selectedLanguage]}
							</a>
						</div>
						{i > 0 && (
							<div style={{ "margin-left": "-50px" }} className="col-md-2">
								<a
									href="javascript:;"
									onClick={() => deleteFormRow(i)}
									className="btn btn-sm font-weight-bolder btn-light-danger mt-10"
								>
									<i className="la la-trash-o"></i>
									{cancel[selectedLanguage]}
								</a>
							</div>
						)}
						<div className="col-md-1"></div>
					</div>
				</div>
			</div>
		));
	};

	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">नाम</label>
							<input
								type="text"
								className="form-control"
								placeholder="नाम"
								name="name"
								onChange={formik.handleChange}
								value={formik.values?.name}
							/>
						</div>
						<div className="col-md-6">
							<label className="form-control-label">वडा नम्बर</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={
									formik.values && formik.values.ward
										? formik.values.ward
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{ward.map((woda) => (
									<option value={woda.value}>{woda[selectedLanguage]}</option>
								))}
							</select>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">ठेगाना</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								name="address"
								onChange={formik.handleChange}
								value={formik.values?.address}
							/>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">संस्थान प्रकार</label>
							<select
								className="form-control"
								id="healthType"
								name="healthType"
								onChange={formik.handleChange}
								value={
									formik.values && formik.values.healthType
										? formik.values.healthType
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{hospitalType.map((type) => (
									<option value={type.value}>{type[selectedLanguage]}</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<label className="form-control-label">शैया संख्या</label>
							<input
								type="number"
								className="form-control"
								placeholder=""
								name="shayaCount"
								onChange={formik.handleChange}
								value={formik.values?.shayaCount}
							/>
						</div>
					</div>
					<hr />
					<h3>कार्यरत स्वास्थ्यकर्मीको संख्या</h3>
					<div className="form-group row">
						<div className="col-md-6">
							<label>आर्थिक वर्ष</label>
							<input
								type="string"
								name="year"
								className="form-control"
								maxlength="4"
								id="year"
								// pattern="/[^0-9.]/g"
								onChange={formik.handleChange}
								value={
									formik.values &&
									formik.values.healths &&
									formik.values.healths[0].year
								}
								required
							/>
						</div>
					</div>
					{getHealthCountFormSection()}

					<h3>स्वास्थ्य संस्था र उपलब्ध सेवाहरुको विवरण</h3>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								स्वास्थ्य संस्थाका सेवाहरु
							</label>
							<select
								className="form-control"
								id="healthFacility"
								name="healthFacility"
								onChange={formik.handleChange}
								value={formik.values?.healthFacility}
								multiple={true}
							>
								{healthFacility?.map((facility) => (
									<option value={facility.value}>{facility.nepali}</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<label>फोटो</label>
							<ImageUploader fileListContent={props.fileListContent} />
						</div>
					</div>
					<div className="card-footer text-right">
						<FormFooterButtons />
					</div>
				</Card.Body>
			</Card>
		</form>
	);
};

export default Hospital;
