// import { render } from 'node-sass'
import React, { useContext } from "react";
import { Button, Card } from "react-bootstrap";
import Table from "rc-table";
import { Categories } from "../data/Categories.js";
import {
	addInstituteData,
	deleteInstituteData,
	getTableData,
} from "../modules/InstituteSurvey/_redux/surveyCrud.js";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import { SubCatRouteContext } from "../providers/SubCatRouteProvider.js";
import SurveyList from "./SurveyList.js";

export const SubCatRoute = React.createContext();
export function useSubCatRoute() {
	return useContext(SubCatRoute);
}
export class InstituteSurveyForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			category: {
				id: 0,
				title: "",
				pageTitle: "",
				subCategories: [],
			},
			subCategories: [],
			selectedSubCategoryId: 0,
			subCategoryComponent: null,
			subCatRoute: "",
			savedSubCatRoute: "",
			createOn: false,
			data: null,
			fileList: {},
			loading: false,
			totalPages: 0,
			setColumn: [],
			displayData: [],
			tableData: [],
			tableTitle: "",
			selectedCategoryObj: {},
		};
		// // this.change = this.change.bind(this);
	}

	getAddForm = () => {
		this.setState({
			...this.state,
			createOn: true,
		});
	};

	componentDidMount() {
		const subCatRoute = this.props.match.params.routeName;
		const categoryId = this.props.match.params.id;
		let subCat = Categories.slice(0).reduce((prev, eachD, i, arr) => {
			const findItem =
				prev || eachD.subCategories.find((item) => item.route === subCatRoute);
			if (typeof findItem !== "undefined") arr.splice(1); // ejects early
			return findItem;
		}, undefined);
		const category = Categories.find((i) => i.id == categoryId);
		this.setState({ category });
		this.setState({ selectedCategoryObj: subCat });
		this.setState({
			selectedSubCategoryId: subCat.id,
		});
		this.setState({ subCatRoute: subCat.route });
		this.setState({
			savedSubCatRoute: subCat.route,
		});
		this.setState({
			subCategoryComponent: subCat.component,
		});
		this.setState({
			fileList: {
				[subCat.route]: {
					image: "",
					fileName: "",
				},
			},
		});
		this.setState({ tableTitle: subCat.title });

		this.setState({ loading: true });
		//
	}
	change = function(event) {
		let subCatId = event.target.value;
		let subCat = this.state.subCategories.find((i) => i.id == subCatId);
		this.setState({
			subCategoryComponent: subCat.component,
			selectedSubCategoryId: subCatId,
			subCatRoute: subCat.route,
			savedSubCatRoute: subCat.route,
			fileList: {
				[subCat.route]: {
					image: "",
					fileName: "",
				},
			},
		});
	};
	newData = () => {
		console.log(this.state);
		this.setState({
			...this.state,
			createOn: true,
		});
	};
	editData = (id) => {
		this.props.history.push(
			`/survey/form/${this.state.category.id}/${this.state.selectedSubCategoryId}/edit/${id}`
		);
	};
	viewData = (id) => {
		this.props.history.push(
			`/survey/form/${this.state.category.id}/${this.state.selectedSubCategoryId}/view/${id}`
		);
	};
	submitData = (formData) => {
		addInstituteData(this.state.subCatRoute, formData).then((res) => {
			console.log(res);
			if (res.data) {
				console.log(res.data);
				this.setState(
					(prev) => {
						return {
							...prev,
							createOn: false,
							subCatRoute: "",
						};
					},
					() =>
						this.setState((pre) => {
							return {
								...pre,
								subCatRoute: pre.savedSubCatRoute,
							};
						})
				);
			}
		});
	};
	deleteData = (id) => {
		if (window.confirm("के तपाई यो डाटा हटाउन चाहनुहुन्छ?")) {
			deleteInstituteData(this.state.subCatRoute, id).then((res) => {
				this.setState(
					(prev) => {
						return {
							...prev,
							subCatRoute: "",
						};
					},
					() =>
						this.setState((pre) => {
							return {
								...pre,
								subCatRoute: pre.savedSubCatRoute,
							};
						})
				);
			});
		}
	};

	handlePageChange = (val) => {
		const start = val * 10;
		const end = start + 10;
		const dispData = this.state.tableData.slice(start, end);
		this.setState({ displayData: dispData });
	};

	report = () => {
		this.props.history.push(
			`/survey/report/${this.props.match.params.id}/${this.state.subCatRoute}`
		);
	};
	render() {
		return (
			<>
				<Card style={{ width: "100%" }}>
					<Card.Body>
						<Card.Title>
							<h1 className="mb-10">{this.state.category.title}</h1>
						</Card.Title>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="form-control-label">
									{this.state.category.pageTitle} {this.state.tableTitle}
								</label>
							</div>
							{!this.state.createOn ? (
								<div className="col-md-6 d-flex justify-content-end align-items-center">
									<Button
										variant="light-success"
										className="mr-3"
										onClick={this.report}
									>
										{/* <i style={{ position: "relative", bottom: "1px" }} className="flaticon2-add-1 icon-nm"></i> */}
										रिपोर्ट
									</Button>
									<Button variant="light-primary" onClick={this.getAddForm}>
										<i
											style={{ position: "relative", bottom: "1px" }}
											className="flaticon2-add-1 icon-nm"
										></i>
										थप्नुहोस
									</Button>
								</div>
							) : null}
						</div>

						{/* {this.state.loading && <Loader loading={this.state.loading} />} */}
						{!this.state.createOn && (
							<SurveyList
								editData={this.editData}
								deleteData={this.deleteData}
								viewData={this.viewData}
								subCatRoute={this.state.subCatRoute}
							/>
						)}
						{/* {!this.state.createOn && !this.state.loading && (
							<>
								<Table
									className="table table-hover"
									columns={this.state.setColumn}
									data={this.state.displayData}
								/>
								<Pagination
									pages={this.state.totalPages}
									handlePageChange={this.handlePageChange}
								/>
							</>
						)} */}
					</Card.Body>
				</Card>
				{this.state.createOn && this.state.subCategoryComponent && (
					<SubCatRouteContext
						route={this.state.subCatRoute}
						fileListContent={this.state.fileList}
					>
						<this.state.subCategoryComponent
							submit={this.submitData}
							fileListContent={this.state.fileList}
						/>
					</SubCatRouteContext>
				)}
			</>
		);
	}
}
