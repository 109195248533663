import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import {
	add,
	wardQuestion,
	photo,
	count,
	amount,
	organization,
	disasterinfra,
	information,
	effectofDeath,
	effectofInjured,
	disasterType,
	informations,
	ward,
	selectedLanguage,
	cancel,
	organizationsName,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
// import { KTFormRepeater } from '../data/helpers/Repeater_form.js'
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	data: {
		ward: "",
		disasterType: "",
		information: [{ organization: "", amount: 0, count: 0 }],
		uniqueIdentifier: GetUUID(),
	},
};

const DisasterInfrastructure = (props) => {
	const [apiData, setApiData] = useState({});
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		},
	});
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						information: props.data.information?.length
							? props.data.information
							: [{ organization: "", amount: 0, count: 0 }],
					},
				};
			});
		} else {
			setApiData(initData);
		}
		console.log(props);
	}, [props]);
	const addFormRow = () => {
		const prevInformation = formik.values.information;
		prevInformation.push({ organization: "", amount: 0, count: 0 });
		formik.setValues({
			...formik.values,
			information: prevInformation,
		});
	};
	const deleteFormRow = (index) => {
		const prevInformation = formik.values.information;
		prevInformation.splice(index, 1);
		formik.setValues({
			...formik.values,
			information: prevInformation,
		});
	};
	const getFormRowList = () => {
		return formik.values?.information.map((row, i) => (
			<div className="form-group row" key={i}>
				<div className="col-lg-10">
					<div className="form-group row align-items-center">
						<div className="col-md-3">
							<label>{organizationsName[selectedLanguage]}</label>
							<select
								className="form-control"
								id="ward"
								name={`information[${i}].organization`}
								onChange={formik.handleChange}
								value={
									formik.values &&
									formik.values.information[i] &&
									formik.values.information[i].organization
										? formik.values.information[i].organization
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{informations.map((level) => (
									<option value={level.value}>{level[selectedLanguage]}</option>
								))}
							</select>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{amount[selectedLanguage]}</label>
							<input
								type="text"
								className="form-control"
								name={`information[${i}].amount`}
								onChange={formik.handleChange}
								value={formik.values?.information[i].amount}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{count[selectedLanguage]}</label>
							<input
								type="number"
								className="form-control"
								name={`information[${i}].count`}
								onChange={formik.handleChange}
								value={formik.values?.information[i].count}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<a
								href="javascript:;"
								onClick={() => deleteFormRow(i)}
								className="btn btn-sm font-weight-bolder btn-light-danger mt-10"
							>
								<i className="la la-trash-o"></i>
								{cancel[selectedLanguage]}
							</a>
						</div>
					</div>
				</div>
			</div>
		));
	};
	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{disasterType[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="disasterType"
								name="disasterType"
								onChange={formik.handleChange}
								value={
									formik.values && formik.values.disasterType
										? formik.values.disasterType
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{disasterType.map((type) => (
									<option value={type.value}>{type[selectedLanguage]}</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<label className="form-control-label">
								{wardQuestion[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={
									formik.values && formik.values.ward
										? formik.values.ward
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{ward.map((woda) => (
									<option value={woda.value}>{woda[selectedLanguage]}</option>
								))}
							</select>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-6">
							<label>{photo[selectedLanguage]}</label>
							<ImageUploader fileListContent={props.fileListContent} />
						</div>
					</div>
					<div>
						<hr />
						<h3>{disasterinfra[selectedLanguage]}</h3>
						{getFormRowList()}
						<div className="form-group row">
							<div className="col-lg-4">
								<a
									href="javascript:;"
									onClick={addFormRow}
									className="btn btn-sm font-weight-bolder btn-light-primary"
								>
									<i className="la la-plus"></i>
									{add[selectedLanguage]}
								</a>
							</div>
						</div>
					</div>

					<div className="card-footer text-right">
						<FormFooterButtons />
					</div>
				</Card.Body>
			</Card>
		</form>
	);
};

export default DisasterInfrastructure;
