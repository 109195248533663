import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	ward,
	selectedLanguage,
	add,
	cancel,
	photo,
	wardQuestion,
	maternityDeathSixWeek,
	aliveChild,
	deathChild,
	deathChildTwnetyNineDays,
	femaleCountQs,
	permanent,
	temporary,
	femaleHealthWorker,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	maternityDeathSixWeek: "",
	aliveChild: "",
	deathChild: "",
	deathChildTwnetyNineDays: "",
	femaleCount: "",
	femalePermanentPlan: "",
	femaleTemporaryPlan: "",
	malePermanentPlan: "",
	maleTemporaryPlan: "",
	femaleHealthWorker: "",
	uniqueIdentifier: GetUUID(),
};

const FemaleChildHealth = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.previousYearPregnant) {
				//     errors.previousYearPregnant = 'Required';
				// }
				// if (!values.ttFirstPregnant) {
				//     errors.ttFirstPregnant = 'Required';
				// }
				// if (!values.ttSecondPregnant) {
				//     errors.ttSecondPregnant = 'Required';
				// }
				// if (!values.fourTestPregnant) {
				//     errors.fourTestPregnant = 'Required';
				// }
				// if (!values.maternityHealthPost) {
				//     errors.maternityHealthPost = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.maternityProfessional) {
				//     errors.maternityProfessional = 'Required';
				// }
				// if (!values.maternityWithoutProfessional) {
				//     errors.maternityWithoutProfessional = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{maternityDeathSixWeek[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={maternityDeathSixWeek[selectedLanguage]}
											name="maternityDeathSixWeek"
											onChange={handleChange}
											value={values.maternityDeathSixWeek}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="maternityDeathSixWeek"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{aliveChild[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={aliveChild[selectedLanguage]}
											name="aliveChild"
											onChange={handleChange}
											value={values.aliveChild}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="aliveChild"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{deathChild[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={deathChild[selectedLanguage]}
											name="deathChild"
											onChange={handleChange}
											value={values.deathChild}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="deathChild"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{deathChildTwnetyNineDays[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={deathChildTwnetyNineDays[selectedLanguage]}
											name="deathChildTwnetyNineDays"
											onChange={handleChange}
											value={values.deathChildTwnetyNineDays}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="deathChildTwnetyNineDays"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{femaleCountQs[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={femaleCountQs[selectedLanguage]}
											name="femaleCountQs"
											onChange={handleChange}
											value={values.femaleCountQs}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="femaleCountQs"
											component="div"
										/>
									</div>
								</div>

								<hr />
								<h3>महिला</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{permanent[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={permanent[selectedLanguage]}
											name="femalePermanentPlan"
											onChange={handleChange}
											value={values.femalePermanentPlan}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="femalePermanentPlan"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{temporary[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={temporary[selectedLanguage]}
											name="femaleTemporaryPlan"
											onChange={handleChange}
											value={values.femaleTemporaryPlan}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="femaleTemporaryPlan"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>पुरुष</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{permanent[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={permanent[selectedLanguage]}
											name="malePermanentPlan"
											onChange={handleChange}
											value={values.malePermanentPlan}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="malePermanentPlan"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{temporary[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={temporary[selectedLanguage]}
											name="maleTemporaryPlan"
											onChange={handleChange}
											value={values.maleTemporaryPlan}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="maleTemporaryPlan"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{femaleHealthWorker[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={femaleHealthWorker[selectedLanguage]}
											name="femaleHealthWorker"
											onChange={handleChange}
											value={values.femaleHealthWorker}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="femaleHealthWorker"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default FemaleChildHealth;
