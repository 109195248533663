import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	wardQuestion,
	photo,
	selectedLanguage,
	cancel,
	ward,
} from "../data/institute";

import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
const PalikaSamiti = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							name: "",
							ward: "",
							chairmanName: "",
							chairmanContact: "",
							workArea: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				if (!values.name) {
					errors.name = "Required";
				}
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.bankType) {
				//     errors.bankType = 'Required';
				// }
				// if (!values.annualTransaction) {
				//     errors.annualTransaction = 'Required';
				// }
				// if (!values.investment) {
				//     errors.investment = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.bankService) {
				//     errors.bankService = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">समितिको नाम</label>
										<input
											type="text"
											className="form-control"
											placeholder="समितिको नाम"
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											अध्यक्षको नाम थर
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="अध्यक्षको नाम थर"
											name="chairmanName"
											onChange={handleChange}
											value={values.chairmanName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="chairmanName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											अध्यक्षको सम्पर्क नम्बर
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="अध्यक्षको सम्पर्क नम्बर"
											name="chairmanContact"
											onChange={handleChange}
											value={values.chairmanContact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="chairmanContact"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											कार्य गर्ने क्षेत्र
										</label>
										<textarea
											class="form-control"
											name="workArea"
											placeholder="कार्य गर्ने क्षेत्र"
											rows="3"
											onChange={handleChange}
											value={values.workArea}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="workArea"
											component="div"
										/>
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default PalikaSamiti;
