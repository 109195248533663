import React from "react";
import { Formik, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import { Card } from "react-bootstrap";

import FormFooterButtons from "../components/FormFooterButtons";
import { ward } from "../data/institute";
const BusinessInstitute = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							ward: "",
							companyName: "",
							annualIncome: "",
							businessName: "",
							businessType: "",
							partnership: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.pondAddress) {
				//     errors.pondAddress = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.farmType) {
				//     errors.farmType = 'Required';
				// }
				// if (!values.unit) {
				//     errors.unit = 'Required';
				// }
				// if (!values.rate) {
				//     errors.rate = 'Required';
				// }
				// if (!values.animal) {
				//     errors.animal = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.total) {
				//     errors.total = 'Required';
				// }
				// if (!values.fetotal) {
				//     errors.fetotal = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">वडा नम्बर </label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											व्यापार तथा व्यसाय संचालक तथा कम्पनीको नाम
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="व्यापार तथा व्यसाय संचालक तथा कम्पनीको नाम"
											name="companyName"
											onChange={handleChange}
											value={values.companyName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="companyName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											व्यापार र व्यवसायको किसिम
										</label>
										<select
											className="form-control"
											id="businessType"
											name="businessType"
											onChange={handleChange}
											value={values.businessType}
										>
											<option selected disabled>
												कुनै एक छान्नुहोस्
											</option>
											<option value="kirana">किराना वा व्यापरिक वस्तु</option>
											<option value="meat">मासु विक्री केन्द्र </option>
											<option value="tarkari">
												तरकारी तथा फलफूल विक्री केन्द्र
											</option>
											<option value="milk">
												दुध चिस्यान तथा विक्री केन्द्र
											</option>
											<option value="develop">निर्माण सामाग्री</option>
											<option value="medicine">औषधी पसल वा क्लिनिक</option>
											<option value="maintenance">मर्मत सेवा केन्द्र</option>
											<option value="electronics">
												इलेक्ट्रिकल तथा इलेक्ट्रोनिक्स पसल
											</option>
											<option value="hotel">होटेल तथा रेष्टुरेन्ट</option>
											<option value="fancy">कपडा तथा फेन्सी</option>
											<option value="beauty_parlor">ब्युटीपार्लर</option>
											<option value="buevision">विउविजन</option>
											<option value="others">अन्य (भए खुलाउनुहोस)</option>
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="businessType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									{values.businessType === "others" && (
										<div className="col-md-6">
											<label className="form-control-label">
												अन्य (भए खुलाउनुहोस)
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="अन्य (भए खुलाउनुहोस)"
												name="businessTypeOthers"
												onChange={handleChange}
												value={values.businessTypeOthers}
											/>
										</div>
									)}
									<div className="col-md-6">
										<label className="form-control-label">पसलको प्रकार</label>
										<input
											type="text"
											className="form-control"
											placeholder="पसलको प्रकार"
											name="shopType"
											onChange={handleChange}
											value={values.shopType}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="shopType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											ब्यापार व्यवसायको स्वामित्व
										</label>
										<select
											className="form-control"
											id="partnership"
											name="partnership"
											onChange={handleChange}
											value={values.partnership}
										>
											<option selected disabled>
												कुनै एक छान्नुहोस्
											</option>
											<option value="alone">एकल </option>
											<option value="partner">साझेदारी </option>
											<option value="sahakari">सहकारी</option>
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="partnership"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											वार्षिक कारोबार राख्ने
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="वार्षिक कारोबार राख्ने"
											name="annualIncome"
											onChange={handleChange}
											value={values.annualIncome}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualIncome"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default BusinessInstitute;
