import React from "react";
import { Formik, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	sanchitaAreaType,
	areaList,
	add,
	photo,
	name,
	sourceArea,
	sourceRate,
	irrigationMedium,
	irrigationMediumQn,
	availableQn,
	irrigationTypeQn,
	irrigationType,
	areaPlace,
	wardQuestion,
	owners,
	availability,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";

const Irrigation = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							irrigationType: "",
							irrigationMedium: "",
							ward: "",
							name: "",
							sourceArea: "",
							area: "",
							owner: "",
							availability: "",
							sourceRate: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				if (!values.ward) {
					errors.ward = "Required";
				}
				// if (!values.pondAddress) {
				//     errors.pondAddress = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.farmType) {
				//     errors.farmType = 'Required';
				// }
				// if (!values.unit) {
				//     errors.unit = 'Required';
				// }
				// if (!values.rate) {
				//     errors.rate = 'Required';
				// }
				// if (!values.animal) {
				//     errors.animal = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.total) {
				//     errors.total = 'Required';
				// }
				// if (!values.fetotal) {
				//     errors.fetotal = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{irrigationTypeQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="irrigationType"
											name="irrigationType"
											onChange={handleChange}
											value={values?.irrigationType}
										>
											<option>कुनै छान्नुहोस</option>
											{irrigationType.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="irrigationType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{irrigationMediumQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="irrigationMedium"
											name="irrigationMedium"
											onChange={handleChange}
											value={values?.irrigationMedium}
										>
											<option>कुनै छान्नुहोस</option>
											{irrigationMedium.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="irrigationMedium"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{sourceArea[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={sourceArea[selectedLanguage]}
											name="sourceArea"
											onChange={handleChange}
											value={values.sourceArea}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="sourceArea"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{sourceRate[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={sourceRate[selectedLanguage]}
											name="sourceRate"
											onChange={handleChange}
											value={values.sourceRate}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="sourceRate"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{availableQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="availability"
											name="availability"
											onChange={handleChange}
											value={values?.availability}
										>
											<option>कुनै छान्नुहोस</option>
											{availability.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="availability"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{areaPlace[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={areaPlace[selectedLanguage]}
											name="areaPlace"
											onChange={handleChange}
											value={values.areaPlace}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="areaPlace"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{owners[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={owners[selectedLanguage]}
											name="owner"
											onChange={handleChange}
											value={values.owner}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="owner"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Irrigation;
