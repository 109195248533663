import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import {
	add,
	wardQuestion,
	photo,
	address,
	riskArea,
	dead,
	injured,
	effectFromDisasterQn,
	disaster,
	vaccineList,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";
import DatePicker from "react-datepicker";
// import { KTFormRepeater } from '../data/helpers/Repeater_form.js'
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";
import ImageUploader from "../../app/components/ImageUploader";

const initData = {
	data: {
		ward: "",
		fiscalYear: "",
		childrenDetailCount: [{ optionList: "", count: 0 }],
	},
};
const VaccineDetail = (props) => {
	const [apiData, setApiData] = useState({});
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		},
	});
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						childrenDetailCount: props.data.childrenDetailCount?.length
							? props.data.childrenDetailCount
							: [{ optionList: "", count: 0 }],
					},
				};
			});
		} else {
			setApiData(initData);
		}
		console.log(props);
	}, [props]);
	const addFormRow = () => {
		const prevchildrenDetailCount = formik.values?.childrenDetailCount;
		prevchildrenDetailCount.push({ optionList: "", count: 0 });
		formik.setValues({
			...formik.values,
			childrenDetailCount: prevchildrenDetailCount,
		});
	};
	const deleteFormRow = (index) => {
		const prevchildrenDetailCount = formik.values?.childrenDetailCount;
		prevchildrenDetailCount.splice(index, 1);
		formik.setValues({
			...formik.values,
			childrenDetailCount: prevchildrenDetailCount,
		});
	};
	const getFormRowList = () => {
		return formik.values?.childrenDetailCount.map((row, i) => (
			<div className="form-group row" key={i}>
				<div className="col-lg-12">
					<div className="form-group row align-items-center">
						<div className="col-md-3">
							<label>विवरण</label>
							<select
								className="form-control"
								id="ward"
								name={`childrenDetailCount[${i}].optionList`}
								onChange={formik.handleChange}
								value={
									formik.values.childrenDetailCount &&
									formik.values.childrenDetailCount[i] &&
									formik.values.childrenDetailCount[i].optionList
										? formik.values.childrenDetailCount[i].optionList
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{vaccineList.map((level) => (
									<option value={level.value}>{level[selectedLanguage]}</option>
								))}
							</select>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-2">
							<label>संख्या</label>
							<input
								type="number"
								name={`childrenDetailCount[${i}].count`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.childrenDetailCount[i].count}
							/>
							<div className="d-md-none mb-2"></div>
						</div>

						<div style={{ "margin-top": "30px" }} className="col-md-2">
							<a
								href="javascript:;"
								onClick={addFormRow}
								className="btn btn-sm font-weight-bolder btn-light-primary"
							>
								<i className="la la-plus"></i>
								{add[selectedLanguage]}
							</a>
						</div>
						{i > 0 && (
							<div style={{ "margin-left": "-50px" }} className="col-md-2">
								<a
									href="javascript:;"
									onClick={() => deleteFormRow(i)}
									className="btn btn-sm font-weight-bolder btn-light-danger mt-10"
								>
									<i className="la la-trash-o"></i>
									{cancel[selectedLanguage]}
								</a>
							</div>
						)}
						<div className="col-md-1"></div>
					</div>
				</div>
			</div>
		));
	};

	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{wardQuestion[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={
									formik.values && formik.values.ward
										? formik.values.ward
										: "selectOne"
								}
							>
								<option value="selectOne" disabled>
									कुनै एक छान्नुहोस्
								</option>
								{ward.map((woda) => (
									<option value={woda.value}>{woda[selectedLanguage]}</option>
								))}
							</select>
						</div>
					</div>

					<div>
						<hr />
						<h3>
							बालबालिका / खोप लिने बालबालिका / २ बर्ष मुनीका बालबालिका संख्या
						</h3>
						<div className="form-group row">
							<div className="col-md-6">
								<label>आर्थिक वर्ष</label>
								{/* <input
									type="date"
									date="year"
									className="form-control"
									id="example-date-input"
									name="fiscalYear"
									onChange={formik.handleChange}
									value={formik.values?.fiscalYear}
								/> */}
								<input
									type="string"
									name="fiscalYear"
									className="form-control"
									maxlength="4"
									id="fiscalYear"
									// pattern="/[^0-9.]/g"
									onChange={formik.handleChange}
									value={
										formik.values &&
										formik.values.childrenDetailCount &&
										formik.values.childrenDetailCount[0].fiscalYear
									}
									required
								/>
							</div>
						</div>
						{getFormRowList()}

						{/* <div className="form-group row">
							<div className="col-lg-4">
								<a
									href="javascript:;"
									onClick={addFormRow}
									className="btn btn-sm font-weight-bolder btn-light-primary"
								>
									<i className="la la-plus"></i>
									{add[selectedLanguage]}
								</a>
							</div>
						</div> */}

						<div className="card-footer text-right">
							<FormFooterButtons />
						</div>
					</div>
				</Card.Body>
			</Card>
		</form>
	);
};
export default VaccineDetail;
