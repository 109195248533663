// import { render } from 'node-sass'
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Card, Spinner } from 'react-bootstrap'
import { Categories } from '../data/Categories.js';
import GhardhuriForm from '../home-survey-forms/GhardhuriForm.js';
import HomeSurvey1 from '../home-survey-forms/home-survey-1.js';
import KrishiTathaPashu from '../home-survey-forms/KrishiTathaPashu.js';
import MadhyamikGharForm from '../home-survey-forms/MadhyamikGharForm.js';
import MrityuBibaran from '../home-survey-forms/MrityuBibbaran.js';
import PariwarikBibaran from '../home-survey-forms/PariwarikBibaran.js';
import PrathamikGharForm from '../home-survey-forms/PrathamikGharForm.js';
import ArthikBibaran from '../home-survey-forms/ArthikBibaran.js';
import SamajikForm from '../home-survey-forms/SamajikForm.js';
import { getHouseById, homeSurveyDataPost } from '../modules/HomeSurvey/homeSurveyCrud.js';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../_metronic/_helpers/AssetsHelpers.js';
import { useHistory } from 'react-router-dom';

const initialData = {
    isHouseNumberValid: false,
    houseHelperText: {
        english: "please select unique house number in english",
        nepali: "कृपया अंग्रेजीमा अद्वितीय घर नम्बर चयन गर्नुहोस्",
    },
    // maps
    fileList: {
        house: [],
    },
    gpsFetched: false,
    lat: 27.704206,
    lng: 85.331871,
    zoom: 15,
    // page Level State
    primaryHouse: {},
    familyDetail: {
    },
    // death state
    death: {
        anyDeath: null,
        deathCount: 0,
    },
    childMarriage: {
        isChildMarriage: null,
        childCount: 1,
    },
    // insurance: {},
    secondaryHouse: {},
    houseDetail: {},
    agriLivestock: {},
    economics: {},
    business: {},
    womenChild: {},
    socialWelfare: {},
    activeStep: 0,
    completed: [],
    text: "",
    errors: {
        // primaryHouse: {},
        // familyDetail: {},
        // death: {},
        // insurance: {},
        // secondaryHouse: {},
        // houseDetail: {},
        // agriLivestock: {},
        // economics: {},
        // business: {},
        // womenChild: {},
        // socialWelfare: {}

    }
}
const HomeSurveyForm = (props) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [currentForm, setCurrentForm] = useState(null)
    const [loading, setLoading] = useState(false)
    const [apiData, setApiData] = useState(initialData)
    const history = useHistory();
    useEffect(() => {
        const editId = props.match.params.id;
        if (editId) {
            getHouseById(editId).then((res) => {
                if (res.data) {
                    console.log(res.data);
                    setApiData(res.data)
                }
            })
        }
    }, [])
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: apiData,
        validate: (values) => {
            const errors = {};
            return errors;
        },
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true)
            homeSurveyDataPost(values).then((res) => {
                history.push("/home-survey/list")
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            })
        }
    })
    useEffect(() => {

    }, [formik])
    const addMarker = (e) => {
        console.log(e)
        formik.setValues({
            ...formik.values,
            gpsFetched: false,
            lat: e.latlng.lat,
            lng: e.latlng.lng,
        });
    }
    const steps = [
        {
            title: "1. घरधुरी विवरण",
            icon: "/media/svg/hamropalika-icons/stepper/ghardhuriStepper.svg",
        },
        {
            title: "2. परिवार विवरण",
            icon: "/media/svg/hamropalika-icons/stepper/pariwarikStepper.svg",


        },
        {
            title: "3. मृत्यु",
            icon: "/media/svg/hamropalika-icons/stepper/graveStepper.svg",

        },
        {
            title: "4. माध्यमिक घर विवरण",
            icon: "/media/svg/hamropalika-icons/stepper/home1Stepper.svg",

        },
        {
            title: "5. प्राथमिक घर विवरण",
            icon: "/media/svg/hamropalika-icons/stepper/home2Stepper.svg",

        },
        {
            title: "6. कृषि तथा पशु ",
            icon: "/media/svg/hamropalika-icons/stepper/farmStepper.svg",
        },
        {
            title: "7. आर्थिक",
            icon: "/media/svg/hamropalika-icons/stepper/economicStepper.svg",
        },
        {
            title: "7. सामाजिक",
            icon: "/media/svg/hamropalika-icons/stepper/socialStepper.svg",
        },
    ]
    const setStep = (index) => {
        setCurrentForm(steps[index].component)
        setCurrentStep(index);
    }
    useEffect(() => {
        console.log(currentStep)
        console.log(currentForm)
    }, [currentForm])
    const getHouseSurveyHeader = () => (

        <div class="wizard-nav border-bottom">
            <div class="wizard-steps p-8 p-lg-10 flex-wrap">
                {
                    steps.map((step, index) => (
                        <div class="wizard-step mt-5" data-wizard-type="step" data-wizard-state={index <= currentStep ? "current" : ""} onClick={() => setStep(index)}>
                            <div class="wizard-label">
                                {/* <i class={`wizard-icon ${step.icon}`}></i> */}
                                <SVG height="52" width="52" src={toAbsoluteUrl(step.icon)} className={index <= currentStep ? "current-step" : "inactive-step"} />
                                <h3 class="wizard-title">{step.title}</h3>
                            </div>
                            {index !== (steps.length - 1) && (<span class="svg-icon svg-icon-xl wizard-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1" />
                                        <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                    </g>
                                </svg>
                            </span>)}
                        </div>
                    ))
                }

            </div>
        </div>
    )
    const getFormSection = () => {
        switch (currentStep) {
            case 0:
                return (
                    <GhardhuriForm formik={formik} addMarker={addMarker} />
                )
            case 1:
                return (
                    <PariwarikBibaran formik={formik} />
                )
            case 2:
                return (
                    <MrityuBibaran formik={formik} />
                )
            case 3:
                return (
                    <MadhyamikGharForm formik={formik} />
                )
            case 4:
                return (
                    <PrathamikGharForm formik={formik} />
                )
            case 5:
                return (
                    <KrishiTathaPashu formik={formik} />
                )
            case 6:
                return (
                    <ArthikBibaran formik={formik} />
                )
            case 7:
                return (
                    <SamajikForm formik={formik} />
                )
            default:
                break;
        }
        // return (
        //     <currentForm />
        // )
    }
    const getFooterButtons = () => (
        <div className="d-flex justify-content-end">
            {currentStep !== 0 && (<button type="button" class="btn btn-light-primary" onClick={(e) => setStep(currentStep - 1)} >Previous</button>)}
            {currentStep !== (steps.length - 1) && (<button type="button" class="btn btn-primary ml-4" onClick={(e) => setStep(currentStep + 1)} >Next</button>)}
            <button type="submit" class="btn btn-primary ml-4" disabled={loading} >
                {
                    loading ? (<Spinner animation="grow" variant="primary" size="sm" className="mr-2" />) : (<></>)
                }Submit</button>

        </div>
    );
    return (
        <>
            <Card style={{ minHeight: "75vh" }}>
                <Card.Body>

                    <div class="wizard wizard-1" id="kt_wizard" data-wizard-state="step-first" data-wizard-clickable="false">
                        {getHouseSurveyHeader()}
                        <form className="form survey-form" onSubmit={formik.handleSubmit}>
                            {formik && getFormSection()}
                            {getFooterButtons()}
                        </form>
                    </div>
                </Card.Body>
            </Card>

        </>
    );
}

export default HomeSurveyForm;
// export class HomeSurveyForm extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//         };
//     }
//     render() {

//         return (
//             <>
//                 <HomeSurvey1 />
//             </>
//         )
//     }
// }
