import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	wardQuestion,
	photo,
	name,
	capacityQn,
	others,
	othercapacity,
	selfShelter,
	helipad,
	ward,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	selfShelter: "",
	capacity: "",
	othercapacity: "",
	others: "",
	helipad: "",
	uniqueIdentifier: GetUUID(),
};

const SocialResources = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.capacity) {
				//     errors.capacity = 'Required';
				// }
				// if (!values.othercapacity) {
				//     errors.othercapacity = 'Required';
				// }
				// if (!values.selfShelter) {
				//     errors.selfShelter = 'Required';
				// }
				// if (!values.helipad) {
				//     errors.helipad = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.others) {
				//     errors.others = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{capacityQn[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={capacityQn[selectedLanguage]}
											name="capacity"
											onChange={handleChange}
											value={values.capacity}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="capacity"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{othercapacity[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={othercapacity[selectedLanguage]}
											name="othercapacity"
											onChange={handleChange}
											value={values.othercapacity}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="othercapacity"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{selfShelter[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={selfShelter[selectedLanguage]}
											name="selfShelter"
											onChange={handleChange}
											value={values.selfShelter}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="selfShelter"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{helipad[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={helipad[selectedLanguage]}
											name="helipad"
											onChange={handleChange}
											value={values.helipad}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="helipad"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{others[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={others[selectedLanguage]}
											name="others"
											onChange={handleChange}
											value={values.others}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="others"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default SocialResources;
