import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import {
	add,
	address,
	clubActive,
	darta,
	presidentName,
	presidentContact,
	establish,
	latitude,
	longitude,
	memberMaleCount,
	memberFemaleCount,
	name,
	clubHead,
	photo,
	clubMainWork,
	clubUpdateQn,
	clubType,
	wardQuestion,
	clubTypeQn,
	cancel,
	yes,
	no,
	selectedLanguage,
	ward,
} from "../data/institute";
import { Card } from "react-bootstrap";

const Club = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							ward: "",
							name: "",
							address: "",
							clubType: "",
							establishedAt: "",
							memberCount: "",
							clubUpdate: "",
							clubMainWork: "",
							presidentName: "",
							presidentContact: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.clubType) {
				//     errors.clubType = 'Required';
				// }
				// if (!values.establishedAt) {
				//     errors.establishedAt = 'Required';
				// }
				// if (!values.memberCount) {
				//     errors.memberCount = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.clubUpdate) {
				//     errors.clubUpdate = 'Required';
				// }
				// if (!values.clubMainWork) {
				//     errors.clubMainWork = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{clubTypeQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="clubType"
											name="clubType"
											onChange={handleChange}
											value={values.clubType ? values.clubType : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{clubType.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="clubType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{establish[selectedLanguage]}
										</label>
										<input
											type="date"
											className="form-control"
											id="example-date-input"
											placeholder={establish[selectedLanguage]}
											name="establishedAt"
											onChange={handleChange}
											value={values.establishedAt}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="establishedAt"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{memberMaleCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={memberFemaleCount[selectedLanguage]}
											name="memberCountMale"
											onChange={handleChange}
											value={values.memberCountMale}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="memberCountMale"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{memberFemaleCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											id="example-date-input"
											placeholder={memberFemaleCount[selectedLanguage]}
											name="memberCountFemale"
											onChange={handleChange}
											value={values.memberCountFemale}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="memberCountFemale"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{clubUpdateQn[selectedLanguage]}
										</label>
										<div class="radio-inline">
											<label className="radio radio-rounded">
												<input
													type="radio"
													checked="checked"
													name="clubUpdate"
												/>
												<span></span>छ
											</label>
											<label class="radio radio-rounded">
												<input type="radio" name="clubUpdate" />
												<span></span>
												छैन
											</label>
										</div>
										<ErrorMessage
											className="invalid-feedback"
											name="clubUpdate"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>फोटो</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{clubMainWork[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="clubMainWork"
											onChange={handleChange}
											value={values.clubMainWork}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="clubMainWork"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>{clubHead[selectedLanguage]}</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{presidentName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="अध्यक्षको नाम"
											name="presidentName"
											onChange={handleChange}
											value={values.presidentName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{presidentContact[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={presidentContact[selectedLanguage]}
											name="presidentContact"
											onChange={handleChange}
											value={values.presidentContact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentContact"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>

				// <Form>
				//     <Field type="area" name="area" />
				//     <ErrorMessage className="invalid-feedback" name="area" component="div" />
				//     <Field type="password" name="password" />
				//     <ErrorMessage className="invalid-feedback" name="password" component="div" />
				//     <button type="submit" disabled={isSubmitting}>
				//         Submit
				//     </button>
				// </Form>
			)}
		</Formik>
	</div>
);

export default Club;
