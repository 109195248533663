import React from "react";
import { Formik, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { ward } from "../data/institute";

const TourismPlace = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							name: "",
							uniqueIdentifier: "",
							ward: "",
							address: "",
							road: "",
							distance: "",
							ownership: "",
							hotelCount: "",
							total: "",
							establishedAt: "",
							mainBenefit: "",
							touristCount: "",
							tourismType: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.pondAddress) {
				//     errors.pondAddress = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.farmType) {
				//     errors.farmType = 'Required';
				// }
				// if (!values.unit) {
				//     errors.unit = 'Required';
				// }
				// if (!values.rate) {
				//     errors.rate = 'Required';
				// }
				// if (!values.animal) {
				//     errors.animal = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.total) {
				//     errors.total = 'Required';
				// }
				// if (!values.fetotal) {
				//     errors.fetotal = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">वडा नम्बर </label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											<option>कुनै छान्नुहोस</option>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">नाम</label>
										<input
											type="text"
											className="form-control"
											placeholder="नाम"
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											सडकको पहुँच पुगेको/नपुगेको{" "}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="सडकको पहुँच पुगेको/नपुगेको"
											name="road"
											onChange={handleChange}
											value={values.road}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="road"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											होटल, लाज, रेस्टुरा संख्या
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="होटल, लाज, रेस्टुरा संख्या"
											name="hotelCount"
											onChange={handleChange}
											value={values.hotelCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="hotelCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											नजिकको बजार केन्द्र सम्मको दूरी
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="नजिकको बजार केन्द्र सम्मको दूरी"
											name="distance"
											onChange={handleChange}
											value={values.distance}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="distance"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">स्वामित्व</label>
										<input
											type="text"
											className="form-control"
											placeholder="स्वामित्व"
											name="ownership"
											onChange={handleChange}
											value={values.ownership}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ownership"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">ठेगाना</label>
										<input
											type="text"
											className="form-control"
											placeholder="ठेगाना"
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											निर्माण भएको बर्ष
										</label>
										<input
											type="date"
											className="form-control"
											id="example-date-input"
											placeholder="निर्माण भएको बर्ष"
											name="establishedAt"
											onChange={handleChange}
											value={values.establishedAt}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="establishedAt"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">प्रमुख विशेषता</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="mainBenefit"
											onChange={handleChange}
											value={values.mainBenefit}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">प्रकार</label>
										<select
											className="form-control"
											id="tourismType"
											name="tourismType"
											onChange={handleChange}
											value={values.tourismType}
										>
											<option>कुनै छान्नुहोस</option>
											<option value="धार्मिक">
											धार्मिक
											</option>
											<option value="सांस्कृतिक">सांस्कृतिक</option>
											<option value="एतिहासिक">एतिहासिक</option>
											<option value="पर्यटकीय">पर्यटकीय</option>
											<option value="अन्य">अन्य</option>
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="tourismType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											वार्षिक पर्यटक आगमन संख्या
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="वार्षिक पर्यटक आगमन संख्या"
											name="touristCount"
											onChange={handleChange}
											value={values.touristCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="touristCount"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default TourismPlace;
