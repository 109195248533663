import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	wardQuestion,
	photo,
	address,
	riskArea,
	effectFromDisasters,
	possibleRisk,
	ward,
	selectedLanguage,
	cancel,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	riskArea: "",
	address: "",
	effectFromDisasters: "",
	uniqueIdentifier: GetUUID(),
};

const DisasterInformation = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.riskArea) {
				//     errors.riskArea = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.effectFromDisasters) {
				//     errors.effectFromDisasters = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="form-control-label">
									{wardQuestion[selectedLanguage]}
								</label>
								<select
									className="form-control"
									id="ward"
									name="ward"
									onChange={handleChange}
									value={values.ward ? values.ward : "selectOne"}
								>
									<option value="selectOne" disabled>
										कुनै एक छान्नुहोस्
									</option>
									{ward.map((woda) => (
										<option value={woda.value}>{woda[selectedLanguage]}</option>
									))}
								</select>
								<ErrorMessage
									className="invalid-feedback"
									name="ward"
									component="div"
								/>
							</div>
							<div className="col-md-6">
								<label className="form-control-label">
									{riskArea[selectedLanguage]}
								</label>
								<input
									type="text"
									className="form-control"
									placeholder={riskArea[selectedLanguage]}
									name="riskArea"
									onChange={handleChange}
									value={values.riskArea}
								/>
								<ErrorMessage
									className="invalid-feedback"
									name="riskArea"
									component="div"
								/>
							</div>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="form-control-label">
									{address[selectedLanguage]}
								</label>
								<input
									type="text"
									className="form-control"
									placeholder={address[selectedLanguage]}
									name="address"
									onChange={handleChange}
									value={values.address}
								/>
								<ErrorMessage
									className="invalid-feedback"
									name="address"
									component="div"
								/>
							</div>
							<div className="col-md-6">
								<label className="form-control-label">
									{possibleRisk[selectedLanguage]}
								</label>
								<select
									className="form-control"
									id="effectFromDisasters"
									name="effectFromDisasters"
									onChange={handleChange}
									value={values.effectFromDisasters}
									multiple={true}
								>
									{effectFromDisasters.map((disaster) => (
										<option value={disaster.value}>
											{disaster[selectedLanguage]}
										</option>
									))}
								</select>
								<ErrorMessage
									className="invalid-feedback"
									name="effectFromDisasters"
									component="div"
								/>
							</div>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label>{photo[selectedLanguage]}</label>
								<ImageUploader fileListContent={props.fileListContent} />
							</div>
						</div>
						<div className="card-footer text-right">
							<button
								type="submit"
								className="btn btn-primary font-weight-bold mr-2"
							>
								{add[selectedLanguage]}
							</button>
							<button
								type="button"
								className="btn btn-light-primary font-weight-bold"
							>
								{cancel[selectedLanguage]}
							</button>
						</div>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default DisasterInformation;
