import axiosHandler from "../../../../redux/axiosHandler";

export function getTableData(detailName) {
	return axiosHandler.get(`/institute/${detailName}/all/table`);
}
export function getDataDetailsById(id, category) {
	return axiosHandler.get(`/institute/${category}/fetch/${id}`);
}
export function viewDataDetailsById(id, category) {
	return axiosHandler.get(`/institute/${category}/read/${id}`);
}
export function viewInstituteReportById(category) {
	return axiosHandler.get(`/institute/${category}/report`);
}
export function addInstituteData(category, data) {
	return axiosHandler.post(`/institute/${category}/`, data);
}
export function editInstituteData(category, data, dataId) {
	data["_id"] = dataId;
	return axiosHandler.post(`/institute/${category}/`, data);
}
export function deleteInstituteData(category, id) {
	return axiosHandler.delete(`/institute/${category}/delete/${id}`);
}
