import { Button, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { data } from "jquery";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { genderList } from "../../data/advanceSearch";
import { selectedLanguage } from "../../data/institute";
import { registerUserDetails } from "../../modules/Auth/_redux/authCrud";
import { privilege, ward } from "../../data/global";
import Loader from "../Loader";
import * as Yup from "yup";

const defaultValues = {
	email: "",
	firstname: "",
	gender: "",
	lastname: "",
	username: "",
	password: "",
	wardRole: 0,
	privilege: 0,
};
const AddProfileSchema = Yup.object().shape({
	email: Yup.string()
		.email("ईमेल गलत छ")
		.required("इमेल अनिवार्य छ "),
	firstname: Yup.string().required("नाम अनिवार्य छ"),
	lastname: Yup.string().required("थर अनिवार्य छ"),
	username: Yup.string().required("प्रयोगकर्ता नाम अनिवार्य छ"),
	password: Yup.string().required("पासवर्ड अनिवार्य छ"),
	gender: Yup.string().required("लिङ्ग अनिवार्य छ"),
	privilege: Yup.string().required("भूमिका अनिवार्य छ"),
	wardRole: Yup.string().required("वडा अनिवार्य छ"),
});
const AddProfile = (props) => {
	const [initialValues, setInitialValues] = useState(defaultValues);

	const formik = useFormik({
		initialValues,
		validationSchema: AddProfileSchema,
		enableReinitialize: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setSubmitting(true);
			registerUserDetails(values)
				.then((res) => {
					setSubmitting(false);
					if (res.data.success) {
						handleClose();
						toast.success("User details added successfully");
					} else {
						if (res.data) {
							Object.keys(res.data).map((key) => {
								toast.error(res.data[key]);
							});
						}
					}
				})
				.catch((err) => {
					console.log(err.response);
					setSubmitting(false);
					if (err.response.data) {
						Object.keys(err.response.data).map((key) => {
							toast.error(err.response.data[key]);
						});
					}
				});
		},
	});
	const handleClose = () => props.setShow(false);
	const getContent = () => {
		const user = formik.values;
		return (
			<>
				<form onSubmit={formik.handleSubmit}>
					<div className="row">
						<div className="col-md-6 mb-4">
							<div className="form-group">
								<label>ईमेल: </label>
								<input
									type="email"
									className="form-control mr-3"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									name="email"
									value={user.email}
									style={{ fontFamily: "Arial" }}
								/>
								{formik.touched.email && formik.errors.email ? (
									<div class="invalid-feedback">{formik.errors.email}</div>
								) : null}
							</div>
						</div>
						<div className="col-md-6 mb-4">
							<div className="form-group">
								<label>प्रयोगकर्ता नाम: </label>
								<input
									type="text"
									className="form-control mr-3"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									name="username"
									value={user.username}
								/>
								{formik.touched.username && formik.errors.username ? (
									<div class="invalid-feedback">{formik.errors.username}</div>
								) : null}
							</div>
						</div>
						<div className="col-md-6 mb-4">
							<div className="form-group">
								<label>पासवर्ड : </label>
								<input
									type="password"
									className="form-control mr-3"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									name="password"
									value={user.password}
								/>
								{formik.touched.password && formik.errors.password ? (
									<div class="invalid-feedback">{formik.errors.password}</div>
								) : null}
							</div>
						</div>
						<div className="col-md-6 mb-4">
							<div className="form-group">
								<label>नाम: </label>
								<input
									type="text"
									className="form-control mr-3"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									name="firstname"
									value={user.firstname}
								/>
								{formik.touched.firstname && formik.errors.firstname ? (
									<div class="invalid-feedback">{formik.errors.firstname}</div>
								) : null}
							</div>
						</div>
						<div className="col-md-6 mb-4">
							<div className="form-group">
								<label>थर: </label>
								<input
									type="text"
									className="form-control mr-3"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									name="lastname"
									value={user.lastname}
								/>
								{formik.touched.lastname && formik.errors.lastname ? (
									<div class="invalid-feedback">{formik.errors.lastname}</div>
								) : null}
							</div>
						</div>
						<div className="col-md-6 mb-4">
							<div class="form-group">
								<label>लिङ्ग</label>
								<div class="radio-inline">
									{genderList.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`gender`}
												value={value.value}
												checked={value.value === user.gender}
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
											/>
											<span></span> {value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>

						<div className="col-md-6 mb-4">
							<label className="form-group">भूमिका</label>
							<select
								className="form-control"
								id="privilege"
								name="privilege"
								onChange={formik.handleChange}
								value={user.privilege}
							>
								{privilege.map((type) => (
									<option value={type.value}>{type[selectedLanguage]}</option>
								))}
							</select>
							{formik.touched.privilege && formik.errors.privilege ? (
								<div class="invalid-feedback">{formik.errors.privilege}</div>
							) : null}
						</div>
						{user.privilege == 5 && (
							<div className="col-md-6 mb-4">
								<label className="form-group">वडा</label>
								<select
									className="form-control"
									id="wardRole"
									name="wardRole"
									onChange={formik.handleChange}
									value={user.wardRole}
								>
									{/* <option value={0}>गाउँपालिका</option> */}
									<option value={1}>वडा न. १</option>
									<option value={2}>वडा न. २</option>
									<option value={3}>वडा न. ३</option>
									<option value={4}>वडा न. ४</option>
									<option value={5}>वडा न. ५</option>
								</select>
								{formik.touched.wardRole && formik.errors.wardRole ? (
									<div class="invalid-feedback">{formik.errors.wardRole}</div>
								) : null}
							</div>
						)}

						<div className="col-12 d-flex justify-content-end">
							<Button
								variant="light-primary"
								className="mr-3"
								type="submit"
								disabled={formik.isSubmitting}
							>
								{formik.isSubmitting ? (
									<Spinner
										animation="grow"
										variant="primary"
										size="sm"
										className="mr-2"
									/>
								) : null}
								थप्नुहोस्
							</Button>
							<Button variant="light-danger" onClick={handleClose}>
								रद्द गर्नुहोस
							</Button>
						</div>
					</div>
				</form>
			</>
		);
	};
	return (
		<>
			<Modal
				centered
				show={props.show}
				onHide={handleClose}
				scrollable={true}
				dialogClassName="data-detail-modal"
				size="xl"
				style={{ zIndex: "15000" }}
			>
				<Modal.Header closeButton={true}>
					<Modal.Title>प्रयोगकर्ता थप्नुहोस्</Modal.Title>
				</Modal.Header>
				<Modal.Body>{getContent()}</Modal.Body>
			</Modal>
		</>
	);
};

export default AddProfile;
