import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	address,
	name,
	wardQuestion,
	photo,
	area,
	vegetationAnimal,
	grazingTypeQn,
	grazingType,
	mgmtSystem,
	mgmtType,
	areaList,
	unit,
	ward,
	cancel,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	area: "",
	areaType: "",
	address: "",
	grazingType: "",
	mgmtSystem: "",
	vegetation: "",
	uniqueIdentifier: GetUUID(),
};

const GrazingField = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				// 	errors.ward = "Required";
				// }
				// if (!values.name) {
				// 	errors.name = "Required";
				// }
				// if (!values.area) {
				// 	errors.area = "Required";
				// }
				// if (!values.address) {
				// 	errors.address = "Required";
				// }
				// if (!values.grazingType) {
				// 	errors.grazingType = "Required";
				// }
				// if (!values.area) {
				// 	errors.area = "Required";
				// }
				// if (!values.mgmtSystem) {
				// 	errors.mgmtSystem = "Required";
				// }
				// if (!values.vegetation) {
				// 	errors.vegetation = "Required";
				// } else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{mgmtType[selectedLanguage]}{" "}
										</label>
										<select
											className="form-control"
											id="mgmtSystem"
											name="mgmtSystem"
											onChange={handleChange}
											value={
												values.mgmtSystem ? values.mgmtSystem : "selectOne"
											}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{mgmtSystem.map((sys) => (
												<option value={sys.value}>
													{sys[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="mgmtSystem"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{area[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={area[selectedLanguage]}
											name="area"
											onChange={handleChange}
											value={values.area}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="area"
											component="div"
										/>
									</div>
									{/* <div className="col-md-3">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="areaType"
											name="areaType"
											onChange={handleChange}
											value={values.areaType}
										>
											{areaList.map((alist) => (
												<option value={alist.value}>{alist.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="areaType"
											component="div"
										/>
									</div> */}

									{/* <div className="col-md-6">
										<label className="form-control-label">
											{vegetationAnimal[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="vegetation"
											onChange={handleChange}
											value={values.vegetation}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="vegetation"
											component="div"
										/>
									</div> */}
								</div>
								<div className="form-group row">
									{/* <div className="col-md-6">
										<label className="form-control-label">
											{grazingTypeQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="grazingType"
											name="grazingType"
											onChange={handleChange}
											value={values.grazingType}
										>
											{grazingType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="grazingType"
											component="div"
										/>
									</div> */}
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default GrazingField;
