import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	wardQuestion,
	photo,
	remarks,
	name,
	designation,
	phone,
	department,
	branch,
	employeeType,
	selectedLanguage,
	ward,
} from "../data/institute";

const RmStaff = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							name: "",
							department: "",
							phone: "",
							designation: "",
							remarks: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.department) {
				//     errors.department = 'Required';
				// }
				// if (!values.phone) {
				//     errors.phone = 'Required';
				// }
				// if (!values.designation) {
				//     errors.designation = 'Required';
				// }
				// if (!values.remarks) {
				//     errors.remarks = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											<option value="पालिका">पालिका</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{branch[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={branch[selectedLanguage]}
											name="branch"
											onChange={handleChange}
											value={values.branch}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="branch"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{designation[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={designation[selectedLanguage]}
											name="designation"
											onChange={handleChange}
											value={values.designation}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="designation"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{phone[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={phone[selectedLanguage]}
											name="phone"
											onChange={handleChange}
											value={values.phone}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="phone"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{employeeType[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={employeeType[selectedLanguage]}
											name="employeeType"
											onChange={handleChange}
											value={values.employeeType}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="employeeType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">श्रेणी /तह</label>
										<input
											type="text"
											className="form-control"
											placeholder="श्रेणी /तह"
											name="department"
											onChange={handleChange}
											value={values.department}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="department"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{remarks[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={remarks[selectedLanguage]}
											name="remarks"
											onChange={handleChange}
											value={values.remarks}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="remarks"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default RmStaff;
