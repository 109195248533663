import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	address,
	count,
	type,
	instituteTitle,
	bankType,
	workingField,
	instituteHead,
	instituteService,
	name,
	phone,
	photo,
	position,
	mainArea,
	investment,
	annualTransaction,
	wardQuestion,
	selectedLanguage,
	ward,
} from "../data/institute";

const Bank = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							name: "",
							ward: "",
							address: "",
							bankType: "",
							annualTransaction: "",
							investment: "",
							area: "",
							bankService: "",
							presidentName: "",
							presidentContact: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.bankType) {
				//     errors.bankType = 'Required';
				// }
				// if (!values.annualTransaction) {
				//     errors.annualTransaction = 'Required';
				// }
				// if (!values.investment) {
				//     errors.investment = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.bankService) {
				//     errors.bankService = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{type[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="bankType"
											name="bankType"
											onChange={handleChange}
											value={values.bankType}
										>
											{bankType.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="bankType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{instituteService[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="bankService"
											onChange={handleChange}
											value={values.bankService}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="bankService"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{" "}
											{annualTransaction[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={annualTransaction[selectedLanguage]}
											name="annualTransaction"
											onChange={handleChange}
											value={values.annualTransaction}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualTransaction"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{" "}
											{investment[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={investment[selectedLanguage]}
											name="investment"
											onChange={handleChange}
											value={values.investment}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="investment"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{mainArea[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="area"
											onChange={handleChange}
											value={values.area}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="area"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>संस्था प्रमुखको विवरण</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											संस्था प्रमुखको नाम{" "}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="नाम"
											name="presidentName"
											onChange={handleChange}
											value={values.presidentName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">फोन नम्बर</label>
										<input
											type="number"
											className="form-control"
											placeholder="फोन नम्बर"
											name="presidentContact"
											onChange={handleChange}
											value={values.presidentContact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentContact"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>

				// <Form>
				//     <Field type="area" name="area" />
				//     <ErrorMessage className="invalid-feedback" name="area" component="div" />
				//     <Field type="password" name="password" />
				//     <ErrorMessage className="invalid-feedback" name="password" component="div" />
				//     <button type="submit" disabled={isSubmitting}>
				//         Submit
				//     </button>
				// </Form>
			)}
		</Formik>
	</div>
);

export default Bank;
