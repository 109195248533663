/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import ChangePassword from "../../../../../app/components/UserData/ChangePassword";
import EditProfile from "../../../../../app/components/UserData/EditProfile";
import {
	gaupalikaNepali,
	ruralMunicipalNepali,
} from "../../../../../app/data/global";
import { toAbsoluteUrl } from "../../../../_helpers";

const allActions = [
	{
		title: "Edit profile",
		action: "editProfile",
	},
	{
		title: "Change Password",
		action: "changePassword",
	},
	{
		title: "Sign out",
		action: "signOut",
	},
];
export function QuickUser() {
	const history = useHistory();
	const [showChangePassword, setShowChangePassword] = useState(false);
	const [showEditProfile, setShowEditProfile] = useState(false);
	const logoutClick = () => {
		history.push("/logout");
	};
	const closeQuickUser = () => {
		const toggle = document.getElementById("kt_quick_user_toggle");
		if (toggle) {
			toggle.click();
		}
	};
	const handleSelect = (e) => {
		closeQuickUser();
		console.log(e);
		if (e === "signOut") {
			logoutClick();
		} else if (e === "changePassword") {
			setShowChangePassword(true);
		} else if (e === "editProfile") {
			setShowEditProfile(true);
		}
	};

	const handleEditProfileClose = () => {
		setShowEditProfile(false);
	};
	return (
		<div
			id="kt_quick_user"
			className="offcanvas offcanvas-right offcanvas p-10"
		>
			<EditProfile
				setShow={setShowEditProfile}
				handleDeleteClose={handleEditProfileClose}
				show={showEditProfile}
			/>
			<ChangePassword
				setShow={setShowChangePassword}
				show={showChangePassword}
			/>
			<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
				<h3 className="font-weight-bold m-0">
					User Profile
					{/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
				</h3>
				<a
					href="#"
					className="btn btn-xs btn-icon btn-light btn-hover-primary"
					id="kt_quick_user_close"
				>
					<i className="ki ki-close icon-xs text-muted" />
				</a>
			</div>

			<div className="offcanvas-content pr-5 mr-n5">
				<div className="d-flex align-items-center mt-5">
					<div className="symbol symbol-100 mr-5">
						<div
							className="symbol-label"
							style={{
								backgroundImage: `url(${toAbsoluteUrl(
									"/media/users/default.jpg"
								)})`,
							}}
						/>
						<i className="symbol-badge bg-success" />
					</div>
					<div className="d-flex flex-column">
						<a
							href="#"
							className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
						>
							{gaupalikaNepali} {ruralMunicipalNepali}
						</a>
						{/* <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    jm@softplus.com
                  </span>
                </span>
              </a>
            </div> */}
						{/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
						<DropdownButton title="Actions" block onSelect={handleSelect}>
							{allActions.map((action) => {
								return (
									<Dropdown.Item
										as="button"
										key={action.action}
										eventKey={action.action}
									>
										{action.title}
									</Dropdown.Item>
								);
							})}
						</DropdownButton>
					</div>
				</div>

				<div className="separator separator-dashed mt-8 mb-5" />
			</div>
		</div>
	);
}
