import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	wardQuestion,
	helplessWomen,
	countTitle,
	patron,
	helplessChild,
	helplessOld,
	helplessDiable,
	photo,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	wcount: "",
	wpatron: "",
	ccount: "",
	cpatron: "",
	scount: "",
	spatron: "",
	hcount: "",
	hpatron: "",
	uniqueIdentifier: GetUUID(),
};

const Helpless = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.infrastructure) {
				//     errors.infrastructure = 'Required';
				// }
				// if (!values.haatbazaarName) {
				//     errors.haatbazaarName = 'Required';
				// }
				// if (!values.haatrWard) {
				//     errors.haatrWard = 'Required';
				// }
				// if (!values.haatMarket) {
				//     errors.haatMarket = 'Required';
				// }
				// if (!values.haatInfra) {
				//     errors.haatInfra = 'Required';
				// }
				// if (!values.haatType) {
				//     errors.haatType = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<h3>{helplessWomen[selectedLanguage]}</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{countTitle[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="wcount"
											onChange={handleChange}
											value={values.wcount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="wcount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{patron[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder=""
											name="wpatron"
											onChange={handleChange}
											value={values.wpatron}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="wpatron"
											component="div"
										/>
									</div>
								</div>
								<h3>{helplessChild[selectedLanguage]}</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{countTitle[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="ccount"
											onChange={handleChange}
											value={values.ccount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ccount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{patron[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder=""
											name="cpatron"
											onChange={handleChange}
											value={values.cpatron}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="cpatron"
											component="div"
										/>
									</div>
								</div>
								<h3>{helplessOld[selectedLanguage]}</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{countTitle[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="scount"
											onChange={handleChange}
											value={values.scount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="scount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{patron[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder=""
											name="spatron"
											onChange={handleChange}
											value={values.spatron}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="spatron"
											component="div"
										/>
									</div>
								</div>
								<h3>{helplessDiable[selectedLanguage]}</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{countTitle[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="hcount"
											onChange={handleChange}
											value={values.hcount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="hcount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{patron[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder=""
											name="hpatron"
											onChange={handleChange}
											value={values.hpatron}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="hpatron"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Helpless;
