import React from "react";
import { Formik, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { ward } from "../data/institute";

const ProductionTourism = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							productionName: "",
							ward: "",
							ownership: "",
							otherOwnership: "",
							address: "",
							type: "",
							productName: "",
							annualIncome: "",
							female: "",
							male: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.pondAddress) {
				//     errors.pondAddress = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.farmType) {
				//     errors.farmType = 'Required';
				// }
				// if (!values.unit) {
				//     errors.unit = 'Required';
				// }
				// if (!values.rate) {
				//     errors.rate = 'Required';
				// }
				// if (!values.animal) {
				//     errors.animal = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.total) {
				//     errors.total = 'Required';
				// }
				// if (!values.fetotal) {
				//     errors.fetotal = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">वडा नम्बर </label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											<option>कुनै छान्नुहोस</option>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">उधोगको नाम</label>
										<input
											type="text"
											className="form-control"
											placeholder="उधोगको नाम"
											name="productionName"
											onChange={handleChange}
											value={values.productionName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="productionName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">उधोगको प्रकार</label>
										{/* <input
											type="text"
											className="form-control"
											placeholder="उधोगको प्रकार"
											name="productionType"
											onChange={handleChange}
											value={values.productionType}
										/> */}
										<select
											className="form-control"
											id="productionType"
											name="productionType"
											onChange={handleChange}
											value={values.productionType}
										>
											<option>कुनै छान्नुहोस</option>
											<option value="ठुलो">ठुलो</option>
											<option value="मध्यम">मध्यम</option>
											<option value="साना">साना</option>
											<option value="घरेलु">घरेलु</option>
											<option value="लघु">लघु</option>
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="productionType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											उधोगको स्वामित्व
										</label>
										<select
											className="form-control"
											id="ownership"
											name="ownership"
											onChange={handleChange}
											value={values.ownership}
										>
											<option>कुनै छान्नुहोस</option>
											<option value="सरकारी" selected>
												सरकारी{" "}
											</option>
											<option value="पब्लिक">पब्लिक</option>
											<option value="निजि">निजि</option>
											<option value="सहकारी">सहकारी</option>
											<option value="अन्य">अन्य</option>
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ownership"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											उधोगको स्वामित्व अन्यभए उल्लेख गर्नुहोस्
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="अन्य"
											name="otherOwnership"
											onChange={handleChange}
											value={values.otherOwnership}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="otherOwnership"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">ठेगाना</label>
										<input
											type="text"
											className="form-control"
											placeholder="ठेगाना"
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">पुरुष संख्या</label>
										<input
											type="number"
											className="form-control"
											placeholder="पुरुष संख्या"
											name="male"
											onChange={handleChange}
											value={values.male}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="male"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">महिला संख्या</label>
										<input
											type="number"
											className="form-control"
											placeholder="महिला संख्या"
											name="female"
											onChange={handleChange}
											value={values.female}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="female"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											उत्पादन हुने वस्तु र सेवाको नाम
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="उत्पादन हुने वस्तु र सेवाको नाम"
											name="productName"
											onChange={handleChange}
											value={values.productName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="productName"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											वार्षिक आम्दानी
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="उधोग"
											name="annualIncome"
											onChange={handleChange}
											value={values.annualIncome}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualIncome"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default ProductionTourism;
