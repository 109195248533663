import React from "react";
import {
	add,
	wardQuestion,
	photo,
	name,
	jungleName,
	production,
	annualProductions,
	annualCollection,
	areaList,
	unit,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";
import { Formik, ErrorMessage } from "formik";
import { Card } from "react-bootstrap";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	name: "",
	ward: "",
	production: "",
	productionType: "",
	annualProduction: "",
	jungleName: "",
	annualCollection: "",
	uniqueIdentifier: GetUUID(),
};

const Ayurvedic = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.production) {
				//     errors.production = 'Required';
				// }
				// if (!values.annualProduction) {
				//     errors.annualProduction = 'Required';
				// }
				// if (!values.jungleName) {
				//     errors.jungleName = 'Required';
				// }
				// if (!values.annualCollection) {
				//     errors.annualCollection = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{jungleName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={jungleName[selectedLanguage]}
											name="jungleName"
											onChange={handleChange}
											value={values.jungleName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="jungleName"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{annualCollection[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={annualCollection[selectedLanguage]}
											name="annualCollection"
											onChange={handleChange}
											value={values.annualCollection}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualCollection"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{production[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={production[selectedLanguage]}
											name="production"
											onChange={handleChange}
											value={values.production}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="production"
											component="div"
										/>
									</div>
									{/* <div className="col-md-3">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="productionType"
											name="productionType"
											onChange={handleChange}
											value={values.productionType}
										>
											{areaList.map((aList) => (
												<option value={aList.value}>
													{aList[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="productionType"
											component="div"
										/>
									</div> */}
									<div className="col-md-6">
										<label className="form-control-label">
											{annualProductions[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={annualProductions[selectedLanguage]}
											name="annualProduction"
											onChange={handleChange}
											value={values.annualProduction}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualProduction"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Ayurvedic;
