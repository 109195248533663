import React from "react";
import { Formik, ErrorMessage } from "formik";
import {
	add,
	type,
	count,
	height,
	capacity,
	name,
	photo,
	electricityType,
	nationWideElectricity,
	wardQuestion,
	ward,
	selectedLanguage,
	cancel,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	name: "",
	ward: "",
	electricityType: "",
	isNationwide: "",
	capacity: "",
	uniqueIdentifier: GetUUID(),
};

const Electricity = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.riskArea) {
				//     errors.riskArea = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.effectFromDisasters) {
				//     errors.effectFromDisasters = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{type[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="electricityType"
											name="electricityType"
											onChange={handleChange}
											value={values.electricityType}
										>
											{electricityType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="electricityType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{nationWideElectricity[selectedLanguage]}
										</label>
										<div class="radio-inline">
											<label class="radio radio-rounded">
												<input type="radio" checked="checked" name="isNationwide" value="yes" />
												<span></span>छ
											</label>
											<label class="radio radio-rounded">
												<input type="radio" name="isNationwide" value="no" />
												<span></span>
												छैन
											</label>
										</div>
										<ErrorMessage
											className="invalid-feedback"
											name="isNationwide"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{capacity[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={capacity[selectedLanguage]}
											name="capacity"
											onChange={handleChange}
											value={values.capacity}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="capacity"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Electricity;
