export const allToleName = [
	// 1
	{
		nepali: "धारापानी",
		english: "dharapani",
		value: "dharapani",
	},
	{
		nepali: "डसारे",
		english: "dasaare",
		value: "dasaare",
	},
	{
		nepali: "बसिसा",
		english: "basisa",
		value: "basisa",
	},
	{
		nepali: "निम्बुसिसु",
		english: "nimbusisu",
		value: "nimbusisu",
	},
	{
		nepali: "काङलाङ",
		english: "kanglang",
		value: "kanglang",
	},
	{
		nepali: "राङजाङ",
		english: "raangjaang",
		value: "raangjaang",
	},
	{
		nepali: "सोताङ",
		english: "sotaaang",
		value: "sotaaang",
	},
	{
		nepali: "छयाङसिङ",
		english: "chayangsing",
		value: "chayangsing",
	},
	{
		nepali: "गुम्बा",
		english: "ghumba",
		value: "ghumba",
	},
	{
		nepali: "केन्जु",
		english: "kenju",
		value: "kenju",
	},
	{
		nepali: "सिल्दुङ",
		english: "shildung",
		value: "shildung",
	},
	{
		nepali: "सिगारी",
		english: "sigaari",
		value: "sigaari",
	},
	{
		nepali: "नयापुल",
		english: "nayapul",
		value: "nayapul",
	},
	{
		nepali: "पाङलाङ",
		english: "paanglaang",
		value: "paanglaang",
	},
	{
		nepali: "भाल्डूङ",
		english: "bhaaldung",
		value: "bhaaldung",
	},
	{
		nepali: "अन्थली",
		english: "anthali",
		value: "anthali",
	},
	{
		nepali: "धनपाल",
		english: "dhanpaal",
		value: "dhanpaal",
	},
	{
		nepali: "लिस्तिकोट",
		english: "listikot",
		value: "listikot",
	},
	{
		nepali: "लिस्तीपाकु",
		english: "listipaaku",
		value: "listipaaku",
	},
	{
		nepali: "सिसुपाते",
		english: "sisupate",
		value: "sisupate",
	},
	{
		nepali: "थाङपूछे",
		english: "thaangpuchhe",
		value: "thaangpuchhe",
	},
	{
		nepali: "सेबूङ",
		english: "senbu",
		value: "senbu",
	},
	{
		nepali: "च्यामनासा",
		english: "chyamnaasha",
		value: "chyamnaasha",
	},
	{
		nepali: "काउले",
		english: "kaaule",
		value: "kaaule",
	},
	{
		nepali: "बगाम",
		english: "bagaam",
		value: "bagaam",
	},
	{
		nepali: "छगाम",
		english: "chhagaam",
		value: "chhagaam",
	},
	{
		nepali: "आनी गुम्बा टोल",
		english: "aani_gumba",
		value: "aani_gumba",
	},
	{
		nepali: "तल्लोसाबूखानि",
		english: "tallokhabukhaani",
		value: "tallokhabukhaani",
	},
	{
		nepali: "घिप्चे",
		english: "ghipche",
		value: "ghipche",
	},
	{
		nepali: "गोलु",
		english: "golu",
		value: "golu",
	},
	{
		nepali: "तारतुङ",
		english: "taartung",
		value: "taartung",
	},
	{
		nepali: "माथिल्लो साबुखानी",
		english: "maathillo_saabukhaani",
		value: "maathillo_saabukhaani",
	},
	// 2
	{
		nepali: "तातोपानी बजार टोल",
		english: "aarka",
		value: "aarka",
	},
	{
		nepali: "तातोपानी तामाङ गाउँ टोल",
		english: "dhuk",
		value: "dhuk",
	},
	{
		nepali: "तातोपानी कोप्लाङ टोल",
		english: "bharcha",
		value: "bharcha",
	},
	{
		nepali: "दस किलो टोल",
		english: "namsung",
		value: "namsung",
	},
	{
		nepali: "नौ किलो टोल",
		english: "samjung",
		value: "samjung",
	},
	{
		nepali: "कलङसे दुदिले टोल",
		english: "nyanol",
		value: "nyanol",
	},
	{
		nepali: "चौकीडाँडा टोल",
		english: "chaukidandaa",
		value: "chaukidandaa",
	},
	{
		nepali: "न्युसिपाखा टोल",
		english: "nyushipaakha",
		value: "nyushipaakha",
	},
	{
		nepali: "लालविर टोल",
		english: "laalbir_tol",
		value: "laalbir_tol",
	},
	{
		nepali: "भेराक",
		english: "bheraak",
		value: "bheraak",
	},
	{
		nepali: "छेरभाङ",
		english: "chherbhang",
		value: "chherbhang",
	},
	{
		nepali: "कुदुङ टोल",
		english: "kudhung_tol",
		value: "kudhung_tol",
	},
	{
		nepali: "राङकुसा",
		english: "raangkusha",
		value: "raangkusha",
	},
	{
		nepali: "लिपिङ",
		english: "liping",
		value: "liping",
	},
	{
		nepali: "च्यासम डाँडा",
		english: "chyaasam_danda",
		value: "chyaasam_danda",
	},
	{
		nepali: "मानेबजार",
		english: "maanebajar",
		value: "maanebajar",
	},
	{
		nepali: "थामीगाउँ",
		english: "thaami_gaaun",
		value: "thaami_gaaun",
	},
	// 3
	{
		nepali: "लार्चा टोल",
		english: "namgyal",
		value: "namgyal",
	},
	{
		nepali: "खोमाचे टोल",
		english: "thinger",
		value: "thinger",
	},
	{
		nepali: "घुमा चौर टोल",
		english: "fuwa",
		value: "fuwa",
	},
	{
		nepali: "यर्मासिङ्ग टोल",
		english: "yarmaasing_tol",
		value: "yarmaasing_tol",
	},
	{
		nepali: "डाडा टोल",
		english: "dandaa_tol",
		value: "dandaa_tol",
	},
	{
		nepali: "दुमिथाङ टोल",
		english: "dumithaang",
		value: "dumithaang",
	},
	{
		nepali: "दुगुना टोल",
		english: "dugunaa",
		value: "dugunaa",
	},
	{
		nepali: "टासी थांग टोल",
		english: "taansi_thaang_tol",
		value: "taansi_thaang_tol",
	},
	{
		nepali: "यर्मा सिङ्ग टोल",
		english: "yarmaa_singh_toll",
		value: "yarmaa_singh_toll",
	},
	{
		nepali: "खार्कु टोल",
		english: "kharku_tol",
		value: "kharku_tol",
	},
	{
		nepali: "नया बस्ति",
		english: "naya_basti",
		value: "naya_basti",
	},
	{
		nepali: "तार्तुङ्ग टोल",
		english: "tartung_tol",
		value: "tartung_tol",
	},
	{
		nepali: "बोकचेंग टोल",
		english: "bekcheng_tol",
		value: "bekcheng_tol",
	},
	{
		nepali: "छेतांग टोल",
		english: "cheetang_tol",
		value: "cheetang_tol",
	},
	{
		nepali: "घिसिंग डाँडा टोल",
		english: "ghising_tol",
		value: "ghising_tol",
	},
	{
		nepali: "नाम थांग टोल",
		english: "naam_thaang_tol",
		value: "naam_thaang_tol",
	},
	// 4
	{
		nepali: "च्यांग डाँडा",
		english: "chyang_dada",
		value: "chyang_dada",
	},
	{
		nepali: "खड्का टोल",
		english: "khadka_tole",
		value: "khadka_tole",
	},
	{
		nepali: "नारायणथान",
		english: "narayanthan",
		value: "narayanthan",
	},
	{
		nepali: "थामी गाउँ",
		english: "thamigaun",
		value: "thamigaun",
	},
	{
		nepali: "झखानि",
		english: "jhakaani",
		value: "jhakaani",
	},
	{
		nepali: "घिसिंग डाँडा",
		english: "ghising_danda",
		value: "ghising_danda",
	},
	{
		nepali: "पाडपे टोल",
		english: "paadape_tole",
		value: "paadape_tole",
	},
	{
		nepali: "फुल्पांग",
		english: "pulpaang",
		value: "pulpaang",
	},
	{
		nepali: "डाँडा गाउँ",
		english: "danda_gaun",
		value: "danda_gaun",
	},
	{
		nepali: "जंगेवा",
		english: "jangewa",
		value: "jangewa",
	},
	{
		nepali: "सुरासांगे",
		english: "suraasaange",
		value: "suraasaange",
	},
	{
		nepali: "खुपनडोल",
		english: "khupandol",
		value: "khupandol",
	},
	{
		nepali: "हिन्दी",
		english: "hindi",
		value: "hindi",
	},
	{
		nepali: "पन्थली",
		english: "panthali",
		value: "panthali",
	},
	{
		nepali: "लुक्सिंग",
		english: "luksing",
		value: "luksing",
	},
	{
		nepali: "डाक्लन",
		english: "daaklan",
		value: "daaklan",
	},
	{
		nepali: "याइ",
		english: "yaai",
		value: "yaai",
	},
	{
		nepali: "सगरपे",
		english: "sagarpe",
		value: "sagarpe",
	},
	{
		nepali: "तातुंग",
		english: "taatung",
		value: "taatung",
	},
	{
		nepali: "जोंग्बा",
		english: "jombaa",
		value: "jombaa",
	},
	{
		nepali: "पंगसर्यु",
		english: "pangsaryu",
		value: "pangsaryu",
	},
	// 5
	{
		nepali: "व्यान्याली",
		english: "byanyali",
		value: "byanyali",
	},
	{
		nepali: "घुसोंग",
		english: "ghusong",
		value: "ghusong",
	},
	{
		nepali: "नयापुल",
		english: "nayapul",
		value: "nayapul",
	},
	{
		nepali: "थिमिंग डाँडा",
		english: "thiming_danda",
		value: "thiming_danda",
	},
	{
		nepali: "डुङसानी",
		english: "dungsaani",
		value: "dungsaani",
	},
	{
		nepali: "नयाबस्ति",
		english: "nayabasti",
		value: "nayabasti",
	},
	{
		nepali: "त्रिमाङ",
		english: "trimaang",
		value: "trimaang",
	},
	{
		nepali: "अन्याली",
		english: "anyaali",
		value: "anyaali",
	},
	{
		nepali: "किबाले",
		english: "kibaale",
		value: "kibaale",
	},
	{
		nepali: "पार्सो",
		english: "paarso",
		value: "paarso",
	},
	{
		nepali: "गोब्यी",
		english: "gobyi",
		value: "gobyi",
	},
	{
		nepali: "डीगाले",
		english: "digole",
		value: "digole",
	},
	{
		nepali: "थला डाडा",
		english: "thalaa_dada",
		value: "thalaa_dada",
	},
	{
		nepali: "बुल्कुते",
		english: "bulkute",
		value: "bulkute",
	},
	{
		nepali: "च्पामुगाङ",
		english: "chpamugang",
		value: "chpamugang",
	},
	{
		nepali: "वंगा पाठागाङ",
		english: "wanga_paathagang",
		value: "wanga_paathagang",
	},
	{
		nepali: "वमय",
		english: "wamaya",
		value: "wamaya",
	},
	{
		nepali: "सकतवल",
		english: "sakanwal",
		value: "sakanwal",
	},
	{
		nepali: "ज्यामवल्दोङ",
		english: "jyaamwaldong",
		value: "jyaamwaldong",
	},
	{
		nepali: "विङ टोल",
		english: "wing_tole",
		value: "wing_tole",
	},
	{
		nepali: "राप्राङ",
		english: "raaprang",
		value: "raaprang",
	},
	{
		nepali: "मानिसङ",
		english: "maanisang",
		value: "maanisang",
	},
	{
		nepali: "सन्तक",
		english: "santak",
		value: "santak",
	},
	{
		nepali: "बुल्मुसीङ",
		english: "bulmusing",
		value: "bulmusing",
	},
	{
		nepali: "टाटु टोल",
		english: "taatu_tol",
		value: "taatu_tol",
	},
	{
		nepali: "सिलिली",
		english: "silili",
		value: "silili",
	},
	{
		nepali: "गुन्सा",
		english: "gunsaa",
		value: "gunsaa",
	},
	{
		nepali: "भतरमान",
		english: "bhatarmaan",
		value: "bhatarmaan",
	},
	{
		nepali: "डोलेगाङ",
		english: "dolegaaang",
		value: "dolegaaang",
	},
	{
		nepali: "घ्याङले",
		english: "ghyangle",
		value: "ghyangle",
	},
	{
		nepali: "छपादी",
		english: "chapaadi",
		value: "chapaadi",
	},
	{
		nepali: "थामीलाङ",
		english: "thamilaang",
		value: "thamilaang",
	},
	{
		nepali: "गोंदोचे",
		english: "gondoche",
		value: "gondoche",
	},
	{
		nepali: "फाछपानि",
		english: "phachapaani",
		value: "phachapaani",
	},
	{
		nepali: "सप्रङ",
		english: "saprang",
		value: "saprang",
	},
	{
		nepali: "संगमाजी",
		english: "sangamaaji",
		value: "sangamaaji",
	},
	{
		nepali: "पोखरी",
		english: "pokhari",
		value: "pokhari",
	},
	{
		nepali: "चन्दर्कु",
		english: "chandrku",
		value: "chandrku",
	},
	{
		nepali: "ममेयाङ",
		english: "mameyang",
		value: "mameyang",
	},
	{
		nepali: "ज्याम्वादीङ",
		english: "jyabwading",
		value: "jyabwading",
	},
	{
		nepali: "चाकु",
		english: "chaaku",
		value: "chaaku",
	},
	{
		nepali: "लोम्चाटे",
		english: "loomchaate",
		value: "loomchaate",
	},
	{
		nepali: "मेलाम",
		english: "melaam",
		value: "melaam",
	},
];

export const toleWardOne = [
	{
		nepali: "गर्फु",
		english: "garfu",
		value: "garfu",
	},
	{
		nepali: "ढाक्या",
		english: "dhakya",
		value: "dhakya",
	},
	{
		nepali: "निफु",
		english: "chhoser",
		value: "chhoser",
	},
	{
		nepali: "था",
		english: "thaa",
		value: "thaa",
	},
	{
		nepali: "सेकाङ्ग",
		english: "sekang",
		value: "sekang",
	},
	{
		nepali: "सिजागाक",
		english: "sijagak",
		value: "sijagak",
	},
	{
		nepali: "ऐचेन डुक",
		english: "yechen_duk",
		value: "yechen_duk",
	},
	{
		nepali: "छोम",
		english: "chom",
		value: "chom",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardTwo = [
	{
		nepali: "आर्का",
		english: "aarka",
		value: "aarka",
	},
	{
		nepali: "ढुक",
		english: "dhuk",
		value: "dhuk",
	},
	{
		nepali: "भार्चा",
		english: "bharcha",
		value: "bharcha",
	},
	{
		nepali: "नमशुङर",
		english: "namsung",
		value: "namsung",
	},
	{
		nepali: "सम्जुंङ",
		english: "samjung",
		value: "samjung",
	},
	{
		nepali: "न्यानोल",
		english: "nyanol",
		value: "nyanol",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardThree = [
	{
		nepali: "नाम्ग्याल",
		english: "namgyal",
		value: "namgyal",
	},
	{
		nepali: "ठिङ्गर",
		english: "thinger",
		value: "thinger",
	},
	{
		nepali: "फुवा",
		english: "fuwa",
		value: "fuwa",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardFour = [
	{
		nepali: "किम्लिङ",
		english: "kimling",
		value: "kimling",
	},
	{
		nepali: "चुङ्जुङ",
		english: "chungjung",
		value: "chungjung",
	},
	{
		nepali: "न्यम्डो",
		english: "nyamdol",
		value: "nyamdol",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardFive = [
	{
		nepali: "लोमन्थाङ",
		english: "lomangthang",
		value: "lomangthang",
	},
	{
		nepali: "पाँगा",
		english: "panga",
		value: "panga",
	},
	{
		nepali: "छुखोर",
		english: "kholabasti",
		value: "kholabasti",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardSix = [
	{
		nepali: "पिपरा",
		english: "pipara",
		value: "pipara",
	},
	{
		nepali: "भुताहा",
		english: "bhutaha",
		value: "bhutaha",
	},
	{
		nepali: "मुसलमान टोल",
		english: "musalman",
		value: "musalman",
	},
	{
		nepali: "मुसहर  टोल",
		english: "musar",
		value: "musar",
	},
	{
		nepali: "पासवान टोल पिपरा",
		english: "paswan_tole",
		value: "paswan_tole",
	},
	{
		nepali: "राम टोल पिपरा",
		english: "rame_pipara_tole",
		value: "rame_pipara_tole",
	},
	{
		nepali: "नोकेल्ह्वा उत्तर",
		english: "nokalwa_uttar",
		value: "nokalwa_uttar",
	},
	{
		nepali: "नोकेल्ह्वा दक्षिण",
		english: "nokalwa_dakhinn",
		value: "nokalwa_dakhinn",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardSeven = [
	{
		nepali: "नोकेल्ह्वा",
		english: "nokalwa",
		value: "nokalwa",
	},
	{
		nepali: "मोहन्गंज",
		english: "mohangunj",
		value: "mohangunj",
	},
	{
		nepali: "मुसहर  टोल",
		english: "mushar_tole",
		value: "mushar_tole",
	},
	{
		nepali: "पिडारी टोल",
		english: "pidari_tole",
		value: "pidari_tole",
	},
	{
		nepali: "खोरिया टोल",
		english: "khoriya_tole",
		value: "khoriya_tole",
	},
	{
		nepali: "रामपुर",
		english: "rampur",
		value: "rampur",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];
