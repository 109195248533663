import React from 'react'
import { Card } from 'react-bootstrap';
import { annualIncome, beeProduction, fishProduction, pondArea, reshamGhaar, reshamProduction, totalGhaar, totalPond } from '../../data/houseSurvey';
import { selectedLanguage } from '../../data/institute';

const BeeFishSilkDetail = ({ beefishsilk }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    माछा मौरी र रेशम पालन
                </Card.Title>
                <h6>माछा मौरी र रेशम उत्पादन: {beefishsilk?.fishBeeSilk ? "छ" : "छैन"}</h6>
                <h6>विवरण: {beefishsilk?.fishBeeSilk}</h6>
                <h6>{totalPond[selectedLanguage]}: {beefishsilk?.fishBeeSilk}</h6>
                <h6>{pondArea[selectedLanguage]}: {`${beefishsilk?.fish?.ropani}-${beefishsilk?.fish?.aana}-${beefishsilk?.fish?.paisa}`}</h6>
                <h6>{fishProduction[selectedLanguage]}: {beefishsilk?.fish?.production}</h6>
                <h6>{annualIncome[selectedLanguage]}: {beefishsilk?.fish?.annualIncome}</h6>
                <h6>{totalGhaar[selectedLanguage]}: {beefishsilk?.bee?.count}</h6>
                <h6>{beeProduction[selectedLanguage]}: {beefishsilk?.bee?.production}</h6>
                <h6>{reshamGhaar[selectedLanguage]}: {beefishsilk?.silk?.count}</h6>
                <h6>{reshamProduction[selectedLanguage]}: {beefishsilk?.silk?.production}</h6>
            </Card.Body>
        </Card>
    );
}

export default BeeFishSilkDetail;