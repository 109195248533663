import { data } from "jquery";
import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { Categories } from "../data/Categories.js";
import {
	editInstituteData,
	getDataDetailsById,
} from "../modules/InstituteSurvey/_redux/surveyCrud.js";

export class InstituteSurveyEditForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			category: {
				id: 0,
				title: "",
				pageTitle: "",
				subCategories: [],
			},
			subCategories: [],
			selectedSubCategoryId: 0,
			subCategoryComponent: null,
			subCatRoute: "",
			createOn: false,
			data: null,
			savedSubCatRoute: "",
			dataId: 0,
		};
	}
	componentDidMount() {
		const categoryId = this.props.match.params.id;
		const subCatId = this.props.match.params.subCatId;
		const dataId = this.props.match.params.dataId;
		const category = Categories.find((i) => i.id == categoryId);
		const subCatToEdit = category.subCategories.find((i) => i.id == subCatId);
		this.setState({
			subCategories: category.subCategories,
			category,
			subCatRoute: subCatToEdit.route,
			savedSubCatRoute: subCatToEdit.route,
			subCategoryComponent: subCatToEdit.component,
			dataId,
		});
		getDataDetailsById(dataId, subCatToEdit.route).then((res) => {
			console.log(res);
			this.setState({ ...this.state, data: res.data });
		});
	}
	submitData = (formData) => {
		console.log(formData);
		console.log(this.state);
		editInstituteData(this.state.subCatRoute, formData, this.state.dataId).then(
			(res) => {
				if (res.data) {
					console.log(res.data);
					this.setState(
						(prev) => {
							return {
								...prev,
								createOn: false,
								subCatRoute: "",
							};
						},
						() =>
							this.setState((pre) => {
								return {
									...pre,
									subCatRoute: pre.savedSubCatRoute,
								};
							})
					);
				}
			}
		);
	};
	change = function(event) {
		let subCatId = event.target.value;
		let subCat = this.state.subCategories.find((i) => i.id == subCatId);
		this.setState({
			subCategoryComponent: subCat.component,
			selectedSubCategoryId: subCatId,
			savedSubCatRoute: subCat.route,
			subCatRoute: subCat.route,
		});
	};
	newData = () => {
		console.log(this.state);
		this.setState({
			...this.state,
			createOn: true,
		});
	};
	render() {
		return (
			<>
				<Card style={{ width: "100%" }}>
					<Card.Body>
						<Card.Title>
							<h1 className="mb-10">{this.state.category.title}</h1>
						</Card.Title>
					</Card.Body>
				</Card>
				{this.state.data && (
					<this.state.subCategoryComponent
						data={this.state.data}
						submit={this.submitData}
					/>
				)}
			</>
		);
	}
}

// export default InstituteSurveyEditForm;
