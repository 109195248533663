import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import getNepaliNumber from 'get-nepali-number'
import React from 'react'

const EducationSector = ({ data }) => {
    const { functions } = data;
    const handleOpen = (args) => {

    }
    return (
        <Table id="detail-table" className="table-container">
            <TableHead>
                <TableRow>
                    {data.reportTitle.map((header) => (
                        <TableCell>{header}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.bachelorsFemale &&
                    data.bachelorsFemale.map((m, id) => (
                        <TableRow key={id}>
                            <TableCell component="th" scope="row">
                                {m.label}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.bachelorsMale[id].args)
                                }
                            >
                                {getNepaliNumber(data.bachelorsMale[id].value)}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.bachelorsFemale[id].args)
                                }
                            >
                                {getNepaliNumber(m.value)}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.mastersMale[id].args)
                                }
                            >
                                {getNepaliNumber(data.mastersMale[id].value)}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.mastersFemale[id].args)
                                }
                            >
                                {getNepaliNumber(data.mastersFemale[id].value)}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.mPhilMale[id].args)
                                }
                            >
                                {getNepaliNumber(data.mPhilMale[id].value)}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.mPhilFemale[id].args)
                                }
                            >
                                {getNepaliNumber(data.mPhilFemale[id].value)}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.phdMale[id].args)
                                }
                            >
                                {getNepaliNumber(data.phdMale[id].value)}
                            </TableCell>
                            <TableCell
                                align="left"
                                onClick={
                                    functions !== "null" &&
                                    handleOpen(data.phdFemale[id].args)
                                }
                            >
                                {getNepaliNumber(data.phdFemale[id].value)}
                            </TableCell>
                            <TableCell>
                                {getNepaliNumber(
                                    data.bachelorsMale[id].value +
                                    data.bachelorsFemale[id].value +
                                    data.mastersMale[id].value +
                                    data.mastersFemale[id].value +
                                    data.mPhilMale[id].value +
                                    data.mPhilFemale[id].value +
                                    data.phdMale[id].value +
                                    data.phdFemale[id].value
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                {/* <TableRow>
                    <TableCell colspan={1}>जम्मा</TableCell>
                    {data.bachelorsFemale &&
                        [
                            data.bachelorsMale,
                            data.bachelorsFemale,
                            data.mastersMale,
                            data.mastersFemale,
                            data.mPhilMale,
                            data.mPhilFemale,
                            data.phdMale,
                            data.phdFemale,
                        ].map((each) => (
                            <TableCell>
                                {getNepaliNumber(each.reduce((c, a) => c + a.value, 0))}
                            </TableCell>
                        ))} */}
                {/* <TableCell>
                        {getNepaliNumber(single.reduce((c, a) => c + a.value, 0))}
                    </TableCell> */}
                {/* </TableRow> */}
                <TableRow />
            </TableBody>
        </Table>
    )
}

export default EducationSector
