import React, { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { baseURLAPI } from "../../../redux/axiosHandler";
import { livingType } from "../../data/houseSurvey";
import {
	toleWardFive,
	toleWardFour,
	toleWardOne,
	toleWardSeven,
	toleWardThree,
	toleWardTwo,
	toleWardSix,
} from "../../data/ToleList";
import { toAbsoluteUrl } from "../../../../src/_metronic/_helpers/AssetsHelpers";
import { selectedLanguage } from "../../data/institute";

const GharKoParichaya = ({ houseDetails }) => {
	const [toleName, setToleName] = useState(undefined);
	useEffect(() => {
		switch (houseDetails.wardNumber) {
			case 1:
				setToleName(
					toleWardOne.find((i) => i.value === houseDetails.toleName)
						? toleWardOne.find((i) => i.value === houseDetails.toleName)[
								selectedLanguage
						  ]
						: ""
				);
				break;

			case 2:
				setToleName(
					toleWardTwo.find((i) => i.value === houseDetails.toleName)
						? toleWardTwo.find((i) => i.value === houseDetails.toleName)[
								selectedLanguage
						  ]
						: ""
				);
				break;

			case 3:
				setToleName(
					toleWardThree.find((i) => i.value === houseDetails.toleName)
						? toleWardThree.find((i) => i.value === houseDetails.toleName)[
								selectedLanguage
						  ]
						: ""
				);
				break;

			case 4:
				setToleName(
					toleWardFour.find((i) => i.value === houseDetails.toleName)
						? toleWardFour.find((i) => i.value === houseDetails.toleName)[
								selectedLanguage
						  ]
						: ""
				);
				break;

			case 5:
				setToleName(
					toleWardFive.find((i) => i.value === houseDetails.toleName)
						? toleWardFive.find((i) => i.value === houseDetails.toleName)[
								selectedLanguage
						  ]
						: ""
				);
				break;

			case 6:
				setToleName(
					toleWardSix.find((i) => i.value === houseDetails.toleName)
						? toleWardSix.find((i) => i.value === houseDetails.toleName)[
								selectedLanguage
						  ]
						: ""
				);
				break;

			case 7:
				setToleName(
					toleWardSeven.find((i) => i.value === houseDetails.toleName)
						? toleWardSeven.find((i) => i.value === houseDetails.toleName)[
								selectedLanguage
						  ]
						: ""
				);
				break;
			default:
				break;
		}
	}, []);
	return (
		<Card className="mb-5" style={{ minHeight: "20vh" }}>
			<Card.Body>
				<Card.Title>
					<h3>घरको परिचय</h3>
				</Card.Title>
				<div className="row">
					<div className="col-6 col-lg-5">
						<img
							className="house-image mb-3"
							src={`${baseURLAPI}/imageUpload/image/${houseDetails.houseId}_house.jpg`}
							alt=""
							onError={(event) => {
								event.target.src = toAbsoluteUrl(
									"/media/image/NoImageFound.jpg"
								);
								event.onerror = null;
							}}
						/>
					</div>
					<div className="col-6 col-lg-7">
						<div className="detail-content">
							<div className="row">
								<div className="col-lg-7 mb-3">
									<h6>
										वडा नं <span>: {houseDetails.wardNumber}</span>
									</h6>
									{/* <h6>साविक गा.वि.स : <span>{houseDetails.}</span></h6> */}
									<h6>
										टोल वा वस्तीको नाम : <span>{toleName}</span>
									</h6>
									<h6>
										मार्गको नाम (घर छेउको बाटो ) :{" "}
										<span>{houseDetails.streetName}</span>
									</h6>
									<h6>
										परिवारको यहाको बसोबास स्थिती :{" "}
										<span>
											{livingType.find(
												(i) => i.value == houseDetails.livingType
											)
												? livingType.find(
														(i) => i.value == houseDetails.livingType
												  )[selectedLanguage]
												: ""}
										</span>
									</h6>
								</div>
								<div className="col-lg-5  mb-3">
									<h6>
										घर नं <span>: {houseDetails.houseNumber}</span>
									</h6>
									<h6>
										साविक वडा नं: <span> {houseDetails.wardNumber}</span>
									</h6>
									{/* <h6>टोल संकेत नं: <span> {houseDetails.}</span></h6> */}
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<h3>परिवार सदस्य जानकारी</h3>
								</div>
								<div className="col-lg-4 d-flex align-items-center mb-2">
									<img
										height="40"
										className="mr-3"
										src={toAbsoluteUrl("/media/svg/hamropalika-icons/Male.svg")}
										alt=""
									/>
									<h6>पुरुष : {houseDetails.femaleNumber}</h6>
								</div>
								<div className="col-lg-4 d-flex align-items-center mb-2">
									<img
										height="40"
										className="mr-3"
										src={toAbsoluteUrl(
											"/media/svg/hamropalika-icons/assistant.svg"
										)}
										alt=""
									/>
									<h6>महिला : {houseDetails.maleNumber}</h6>
								</div>
								<div className="col-lg-4 d-flex align-items-center mb-2">
									<h6 className="ml-18">
										जम्मा : {houseDetails.totalFamilyNumber}
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default GharKoParichaya;
