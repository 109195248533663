import Table from "rc-table";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import Loader from "../Loader";
import Pagination from "../Pagination";
import { privilege } from "../../data/global";
import { toast } from "react-toastify";
import {
	deleteEmergencyById,
	getEmergencyListData,
} from "../../modules/HomeSurvey/homeSurveyCrud";
import EditEmergency from "./EditEmergency";
import { fetchEmergencyDetails } from "../../modules/Auth/_redux/authCrud";
import { selectedLanguage } from "../../data/institute";
import AddEmergency from "./AddEmergency";

const EmergencyList = (props) => {
	const [columns, setColumns] = useState([]);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [showEditProfile, setShowEditProfile] = useState(false);
	const [showPreviewProfile, setShowPreviewProfile] = useState(false);
	const [deleteProfile, setDeleteProfile] = useState(false);
	const [addProfile, setAddProfile] = useState(false);
	const [usersId, setUserId] = useState("");
	const [deleteEmergencyId, setDeleteEmergencyId] = useState("");
	const [emergencyByIdData, setEmergencyData] = useState({});

	const deleteData = () => {
		deleteEmergencyById(deleteEmergencyId).then((res) => {
			if (res.data) {
				const newData = data.filter((i) => i._id !== deleteEmergencyId);
				const newFilteredData = filteredData.filter(
					(i) => i._id !== deleteEmergencyId
				);
				const newDisplayData = displayData.filter(
					(i) => i._id !== deleteEmergencyId
				);
				setData(newData);
				setFilteredData(newFilteredData);
				setDisplayData(newDisplayData);
				fetHouseList();
				toast.success("Data deleted successfully");
			}
		});
	};
	useEffect(() => {
		fetHouseList();
	}, []);
	useEffect(() => {}, [filteredData]);

	const fetHouseList = () => {
		setColumns([
			{
				title: "क्र.श",
				dataIndex: "sNo",
				key: "sNo",
				width: null,
			},
			{
				title: "संस्थाको नाम",
				dataIndex: "name",
				key: "name",
				width: null,
			},
			{
				title: "सेवा",
				dataIndex: "services",
				key: "services",
				width: null,
			},
			{
				title: "सम्पर्क",
				dataIndex: "contactNumber",
				key: "contactNumber",
				width: null,
			},
			{
				title: "गतिविधि",
				dataIndex: "",
				key: "d",
				render: function(val) {
					return (
						<div className="d-flex table-actions">
							{/* <i
								onClick={(e) => handlePreviewDetailOpen(val._id)}
								className="flaticon-eye mr-3 mb-3"
							></i> */}

							<i
								onClick={(e) => handlePreviewOpen(val._id)}
								className="flaticon-edit mr-3 mb-3"
							></i>

							<i
								className="flaticon-delete"
								onClick={(e) => handleDeleteModalOpen(val._id)}
							></i>
						</div>
					);
				},
			},
		]);

		getEmergencyListData()
			.then((res) => {
				if (res.data) {
					setLoading(false);
					const newData = res.data.map((datum, i) => {
						return {
							...datum,
							sNo: i + 1,
							name: datum.name,
							services: datum.services,
							contactNumber: datum.contactNumber,
						};
					});
					const pages = Math.ceil(res.data.length / 10);
					setTotalPages(pages);
					setData(newData);
					setFilteredData(newData);
					const dispData = newData.slice(0, 10);
					setDisplayData(dispData);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const getPrivilege = (data) => {
		return privilege.filter((each) => each.value === data)[0][selectedLanguage];
	};

	const getGender = (data) => {
		if (data === "female") return "महिला";
		else if (data === "male") return "पुरुष";
		else if (data === "other_sex") return "तेस्रो लिङ्गी";
		else return "";
	};

	const handlePreviewOpen = (id) => {
		setUserId(id);
		setShowEditProfile(true);
	};

	const handleEditProfileClose = () => {
		setUserId("");
		setShowEditProfile(false);
	};

	const handlePreviewDetailOpen = (id) => {
		setShowPreviewProfile(true);
		fetchUserById(id);
	};

	const handleDeleteModalOpen = (id) => {
		setDeleteEmergencyId(id);
		setDeleteProfile(true);
	};

	const fetchUserById = (id) => {
		setLoading(true);
		fetchEmergencyDetails(id)
			.then((res) => {
				setLoading(false);
				const data = res.data.pri.user;
				const userDetails = {
					name: data.name,
					contactNumber: data.contactNumber,
					services: data.services,
					id: id,
					latitude: data.geo.latitude,
					longitude: data.geo.longitude,
				};
				setEmergencyData(userDetails);
			})
			.catch((err) => {
				setLoading(false);
				toast.error("An error occured while fetching user data");
			});
	};

	const handleAddPreviewOpen = () => {
		setAddProfile(true);
		// fetHouseList();
	};

	const handlePageChange = (val) => {
		const start = val * 10;
		const end = start + 10;
		const dispData = filteredData.slice(start, end);
		setDisplayData(dispData);
	};

	const handleClose = () => setShowPreviewProfile(false);
	const handleDeleteClose = () => {
		setDeleteEmergencyId("");
		setDeleteProfile(false);
	};

	const getContent = () => {
		return (
			<>
				{loading && <Loader loading={loading} />}
				{!loading && (
					<div className="text-center">
						<h1 className="" style={{ color: "#006DF0" }}>
							{emergencyByIdData.name}
						</h1>
						<h3>{emergencyByIdData.contactNumber}</h3>
						<h5>{emergencyByIdData.services} </h5>
					</div>
				)}
			</>
		);
	};
	return (
		<>
			<Card style={{ width: "100%", minHeight: "80vh" }}>
				<Card.Body>
					<Card.Title>
						<h1 className="mb-10">आपतकालीन सेवा प्रदायक सम्पर्क विवरण</h1>
					</Card.Title>
					<div className="row">
						<div className="col-12 d-flex justify-content-end align-items-center">
							<Button
								variant="light-primary"
								onClick={(e) => handleAddPreviewOpen()}
							>
								<i
									style={{ position: "relative", bottom: "1px" }}
									className="flaticon2-add-1 icon-nm"
								>
									थप्नुहोस
								</i>
							</Button>
						</div>
					</div>
					<EditEmergency
						setShow={setShowEditProfile}
						handleDeleteClose={handleEditProfileClose}
						show={showEditProfile}
						id={usersId}
					/>
					<AddEmergency setShow={setAddProfile} show={addProfile} />
					{/* delete modal starts*/}
					<Modal centered show={deleteProfile} onHide={handleDeleteClose}>
						<Modal.Body>के तपाई यो डाटा हटाउन चाहनुहुन्छ?</Modal.Body>
						<Modal.Footer>
							<Button color="red" onClick={deleteData}>
								हटाउनहोस
							</Button>{" "}
							<Button color="secondary" onClick={handleDeleteClose}>
								रद्द गर्नुहोस
							</Button>
						</Modal.Footer>
					</Modal>
					{/* delete modal ends */}
					<Modal
						centered
						show={showPreviewProfile}
						onHide={handleClose}
						scrollable={true}
						dialogClassName="data-detail-modal"
						size="l"
						style={{ zIndex: "15000" }}
					>
						{/* <Modal.Header closeButton={true}>
							<Modal.Title>प्रयोगकर्ता सच्यानुहोस</Modal.Title>
						</Modal.Header> */}
						<Modal.Body>{getContent()}</Modal.Body>
					</Modal>

					{loading && <Loader loading={loading} />}
					{!loading && (
						<>
							<Table
								className="table table-hover"
								columns={columns}
								data={displayData}
								data-filter-control="true"
							/>
							<Pagination
								pages={totalPages}
								handlePageChange={handlePageChange}
							/>
						</>
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default EmergencyList;
