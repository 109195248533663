import React from "react";
import { Formik, ErrorMessage } from "formik";
import {
	add,
	roadType,
	roadList,
	roadLength,
	from,
	to,
	benefittedPopulation,
	wardQuestion,
	name,
	areaList,
	photo,
	heightUnit,
	ward,
	cancel,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	roadLength: "",
	roadLengthType: "",
	roadType: "",
	from: "",
	to: "",
	benefittedPopulation: "",
	uniqueIdentifier: GetUUID(),
};

const RoadNetwork = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.riskArea) {
				//     errors.riskArea = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.effectFromDisasters) {
				//     errors.effectFromDisasters = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{roadType[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="roadType"
											name="roadType"
											onChange={handleChange}
											value={values.roadType}
										>
											{roadList.map((info) => (
												<option value={info.value}>
													{info[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="roadType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{roadLength[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={roadLength[selectedLanguage]}
											name="roadLength"
											onChange={handleChange}
											value={values.roadLength}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="roadLength"
											component="div"
										/>
									</div>
									{/* <div className="col-md-3">
										<label className="form-control-label">
											{heightUnit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="roadLengthType"
											name="roadLengthType"
											onChange={handleChange}
											value={values.roadLengthType}
										>
											{areaList.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="roadLengthType"
											component="div"
										/>
									</div> */}
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{from[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={from[selectedLanguage]}
											name="from"
											onChange={handleChange}
											value={values.from}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="from"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{to[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={to[selectedLanguage]}
											name="to"
											onChange={handleChange}
											value={values.to}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="to"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{benefittedPopulation[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={benefittedPopulation[selectedLanguage]}
											name="benefittedPopulation"
											onChange={handleChange}
											value={values.benefittedPopulation}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="benefittedPopulation"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default RoadNetwork;
