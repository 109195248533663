import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import ImageUploader from "../components/ImageUploader";
import { ward } from "../data/institute";

const Hotel = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							name: "",
							ward: "",
							address: "",
							hotelType: "",
							facility: "",
							room: "",
							bed: "",
							level: "",
							ownerName: "",
							registrationNumber: "",
							contact: "",
							renew: "",
							condition: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.clubType) {
				//     errors.clubType = 'Required';
				// }
				// if (!values.establishedAt) {
				//     errors.establishedAt = 'Required';
				// }
				// if (!values.memberCount) {
				//     errors.memberCount = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.clubUpdate) {
				//     errors.clubUpdate = 'Required';
				// }
				// if (!values.clubMainWork) {
				//     errors.clubMainWork = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// else
				// if (
				//     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				// ) {
				//     errors.email = 'Invalid email address';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">नाम</label>
										<input
											type="text"
											className="form-control"
											placeholder="नाम"
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">वडा नम्बर </label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											<option>कुनै छान्नुहोस</option>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">ठेगाना</label>
										<input
											type="text"
											className="form-control"
											placeholder="ठेगाना"
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<h3>क्षमता</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">कोठा संख्या</label>
										<input
											type="number"
											className="form-control"
											id="example-date-input"
											placeholder="कोठा संख्या"
											name="room"
											onChange={handleChange}
											value={values?.room}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="room"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">वेड संख्या</label>
										<input
											type="number"
											className="form-control"
											id="example-date-input"
											placeholder="वेड संख्या"
											name="bed"
											onChange={handleChange}
											value={values?.bed}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="bed"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									{/* <div className="col-md-6">
										<label className="form-control-label">होटेलको स्तर</label>
										<select
											className="form-control"
											id="level"
											name="level"
											onChange={handleChange}
											value={values.level}
										>
											<option>कुनै छान्नुहोस</option>
											<option value="स्टार">स्टार</option>
											<option value="नन स्टार">नन स्टार</option>
											<option value="अन्य">अन्य</option>
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="level"
											component="div"
										/>
									</div> */}
									<div className="col-md-6">
										<label className="form-control-label">कुन किसिम</label>
										<select
											className="form-control"
											id="hotelType"
											name="hotelType"
											onChange={handleChange}
											value={values.hotelType}
										>
											<option>कुनै छान्नुहोस</option>
											<option value="आवासीय">आवासीय</option>
											<option value="रिसोर्ट">रिसोर्ट</option>
											<option value="होमेस्टे">होमेस्टे</option>
											<option value="रेस्टुरेन्ट">रेस्टुरेन्ट</option>
											<option value="अन्य">अन्य</option>
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="hotelType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">संचालकको नाम</label>
										<input
											type="text"
											className="form-control"
											placeholder="संचालकको नाम"
											name="ownerName"
											onChange={handleChange}
											value={values.ownerName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ownerName"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">दर्ता नम्बर</label>
										<input
											type="text"
											className="form-control"
											placeholder="दर्ता नम्बर"
											name="registrationNumber"
											onChange={handleChange}
											value={values.registrationNumber}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="registrationNumber"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">सम्पर्क नम्बर</label>
										<input
											type="text"
											className="form-control"
											placeholder="सम्पर्क नम्बर"
											name="contact"
											onChange={handleChange}
											value={values.contact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="contact"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">नवीकरण गरको</label>
										<input
											type="text"
											className="form-control"
											placeholder="नवीकरण गरको"
											name="renew"
											onChange={handleChange}
											value={values.renew}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="renew"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">अवस्था</label>
										<input
											type="text"
											className="form-control"
											placeholder="सम्पर्क नम्बर"
											name="condition"
											onChange={handleChange}
											value={values.condition}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="condition"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">उपलब्ध सेवाहरु</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="facility"
											onChange={handleChange}
											value={values.facility}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="facility"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>फोटो</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<hr />
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>

				// <Form>
				//     <Field type="area" name="area" />
				//     <ErrorMessage className="invalid-feedback" name="area" component="div" />
				//     <Field type="password" name="password" />
				//     <ErrorMessage className="invalid-feedback" name="password" component="div" />
				//     <button type="submit" disabled={isSubmitting}>
				//         Submit
				//     </button>
				// </Form>
			)}
		</Formik>
	</div>
);

export default Hotel;
