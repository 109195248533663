import React from "react";
import hash from "object-hash";
import { NavLink } from "react-router-dom";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { Button } from "@material-ui/core";
import PointerPlot from "./PointerPlot";
import iconUrl from "../image/pin24.png";
import educationUrl from "../image/education.png";
import officeUrl from "../image/office.png";
import industriesUrl from "../image/industries.png";
import infrastructureUrl from "../image/infrastructure.png";
import workshopUrl from "../image/workshop.png";
import agricultureUrl from "../image/agriculture.png";
import homestayUrl from "../image/homestay.png";
import economicUrl from "../image/economic.png";
import healthUrl from "../image/health.png";
import { BASE_URL } from "../../redux/axiosHandler";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function GeoJsonLayer(props) {
	let allPolygon = [];
	let allPoint = [];

	props.showGeoJSON.length > 0 &&
		props.showGeoJSON.map((each) => {
			if (each.length > 1) {
				Object.values(each).map((eachGeoJSON, index) => {
					if (eachGeoJSON) {
						Object.values(eachGeoJSON.geoJson).map((feature, index) => {
							if (feature instanceof Array) {
								allPolygon.push(feature[0]);
							}
						});
					}
				});
			} else {
				Object.values(each).map((eachGeoJSON, index) => {
					if (eachGeoJSON) {
						Object.values(eachGeoJSON.geoJson).map((feature, index) => {
							console.log("feature", feature);
							if (feature instanceof Array) {
								feature.map((house) => {
									allPoint.push(house);
								});
							}
						});
					}
				});
			}
		});
	var obj = {
		type: "FeatureCollection",
	};
	obj.features = allPolygon;

	var objPoint = {
		type: "FeatureCollection",
	};
	objPoint.features = allPoint;
	// console.log("obj", obj);

	if (
		objPoint.features.findIndex((each) => each.geometry.type === "Point") !== -1
	) {
		return (
			<div>
				<GeoJSON key={hash(obj)} data={obj} />
				<PointerPlot objPoint={objPoint} />
			</div>
		);
	} else {
		return <GeoJSON key={hash(obj)} data={obj} />;
	}
}
