import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import {
	add,
	palikaOffice,
	governmentOfficeList,
	palikaAddress,
	manpower,
	workArea,
	name,
	wardQuestion,
	photo,
	selectedLanguage,
	cancel,
	ward,
} from "../data/institute";

import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
const PalikaOffice = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							name: "",
							ward: "",
							address: "",
							governmentOfficeCategory: "",
							subCategory: "",
							workArea: "",
							manpower: "",
							// investment: "",
							// area: "",
							// presidentName: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.bankType) {
				//     errors.bankType = 'Required';
				// }
				// if (!values.annualTransaction) {
				//     errors.annualTransaction = 'Required';
				// }
				// if (!values.investment) {
				//     errors.investment = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.bankService) {
				//     errors.bankService = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{palikaOffice[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="governmentOfficeCategory"
											name="governmentOfficeCategory"
											onChange={handleChange}
											value={
												values.governmentOfficeList
													? values.governmentOfficeList
													: "selectOne"
											}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{governmentOfficeList.map((list) => (
												<option value={list.value}>
													{list[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="governmentOfficeCategory"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{palikaOffice[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="सरकारी कार्यालय"
											name="subCategory"
											onChange={handleChange}
											value={values.subCategory}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="subCategory"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{palikaAddress[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={palikaAddress[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{manpower[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={manpower[selectedLanguage]}
											name="manpower"
											onChange={handleChange}
											value={values.manpower}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="manpower"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{workArea[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="workArea"
											onChange={handleChange}
											value={values.workArea}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="workArea"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default PalikaOffice;
