import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
	add,
	isHygiene,
	isReconstruct,
	isWater,
	photo,
	address,
	wardQuestion,
	statuskind,
	statusToilet,
	ward,
	selectedLanguage,
	cancel,
	yes,
	no,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	status: "",
	address: "",
	isHygiene: "",
	isReconstruct: "",
	isWater: "",
	uniqueIdentifier: GetUUID(),
};
const PublicToilet = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.riskArea) {
				//     errors.riskArea = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.effectFromDisasters) {
				//     errors.effectFromDisasters = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{isReconstruct[selectedLanguage]}
										</label>
										<div class="radio-inline">
											<label class="radio radio-rounded">
												<Field type="radio" name="isReconstruct" value="yes" />
												<span></span>
												{yes[selectedLanguage]}
											</label>
											<label class="radio radio-rounded">
												<Field type="radio" name="isReconstruct" value="no" />
												<span></span>
												{no[selectedLanguage]}
											</label>
										</div>
										<ErrorMessage
											className="invalid-feedback"
											name="isReconstruct"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{isWater[selectedLanguage]}
										</label>
										<div class="radio-inline">
											<label class="radio radio-rounded">
												<Field type="radio" name="isWater" value="yes" />
												<span></span>छ
											</label>
											<label class="radio radio-rounded">
												<Field type="radio" name="isWater" value="no" />
												<span></span>
												छैन
											</label>
										</div>
										<ErrorMessage
											className="invalid-feedback"
											name="isWater"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{statuskind[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="status"
											name="status"
											onChange={handleChange}
											value={values.status}
										>
											{statusToilet.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="status"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{isHygiene[selectedLanguage]}
										</label>
										<div class="radio-inline">
											<label class="radio radio-rounded">
												<Field type="radio" name="isHygiene" value="yes" />
												<span></span>छ
											</label>
											<label class="radio radio-rounded">
												<Field type="radio" name="isHygiene" value="no" />
												<span></span>
												छैन
											</label>
										</div>
										<ErrorMessage
											className="invalid-feedback"
											name="isHygiene"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default PublicToilet;
