import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	wardQuestion,
	photo,
	name,
	memberTitle,
	descriptionTitle,
	address,
	male,
	female,
	familyTitle,
	dalit,
	janjati,
	others,
	citizenType,
	citizenTitle,
	amountQn,
	investment,
	areaQn,
	selectedLanguage,
	presidentContact,
	presidentName,
	presidentTitle,
	ward,
} from "../data/institute";

const CitizenAware = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={
				props.data
					? props.data
					: {
							ward: "",
							name: "",
							citizenType: "",
							address: "",
							female: "",
							male: "",
							dalit: "",
							janjati: "",
							others: "",
							amount: "",
							investment: "",
							area: "",
							presidentName: "",
							presidentContact: "",
					  }
			}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.citizenType) {
				//     errors.citizenType = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.female) {
				//     errors.female = 'Required';
				// }
				// if (!values.male) {
				//     errors.male = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.dalit) {
				//     errors.dalit = 'Required';
				// }
				// if (!values.janjati) {
				//     errors.janjati = 'Required';
				// }
				// if (!values.others) {
				//     errors.others = 'Required';
				// }
				// if (!values.amount) {
				//     errors.amount = 'Required';
				// }
				// if (!values.investment) {
				//     errors.investment = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">ठेगाना</label>
										<input
											type="text"
											className="form-control"
											placeholder="ठेगाना"
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{citizenTitle[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="citizenType"
											name="citizenType"
											onChange={handleChange}
											value={values.citizenType}
										>
											{citizenType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="citizenType"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>{descriptionTitle[selectedLanguage]}</h3>
								<h4>{memberTitle[selectedLanguage]}</h4>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{male[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={male[selectedLanguage]}
											name="male"
											onChange={handleChange}
											value={values.male}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="male"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{female[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={female[selectedLanguage]}
											name="female"
											onChange={handleChange}
											value={values.female}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="female"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>{familyTitle[selectedLanguage]}</h3>

								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{janjati[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={janjati[selectedLanguage]}
											name="janjati"
											onChange={handleChange}
											value={values.janjati}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="janjati"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{dalit[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={dalit[selectedLanguage]}
											name="dalit"
											onChange={handleChange}
											value={values.dalit}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="dalit"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{others[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={others[selectedLanguage]}
											name="others"
											onChange={handleChange}
											value={values.others}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="others"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{amountQn[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={amountQn[selectedLanguage]}
											name="amount"
											onChange={handleChange}
											value={values.amount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="amount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{investment[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={investment[selectedLanguage]}
											name="investment"
											onChange={handleChange}
											value={values.investment}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="investment"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{areaQn[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={areaQn[selectedLanguage]}
											name="area"
											onChange={handleChange}
											value={values.area}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="area"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<h3>{presidentTitle[selectedLanguage]}</h3>
								<br />
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{presidentName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={presidentName[selectedLanguage]}
											name="presidentName"
											onChange={handleChange}
											value={values.presidentName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{presidentContact[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={presidentContact[selectedLanguage]}
											name="presidentContact"
											onChange={handleChange}
											value={values.presidentContact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentContact"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>

				// <Form>
				//     <Field type="area" name="area" />
				//     <ErrorMessage className="invalid-feedback" name="area" component="div" />
				//     <Field type="password" name="password" />
				//     <ErrorMessage className="invalid-feedback" name="password" component="div" />
				//     <button type="submit" disabled={isSubmitting}>
				//         Submit
				//     </button>
				// </Form>
			)}
		</Formik>
	</div>
);

export default CitizenAware;
