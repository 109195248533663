import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	remove,
	address,
	mainAnimalQn,
	name,
	wardQuestion,
	photo,
	area,
	owner,
	midArea,
	unit,
	areaList,
	cancel,
	selectedLanguage,
	ward,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	area: "",
	areaType: "",
	address: "",
	midArea: "",
	midAreaType: "",
	owner: "",
	mainAnimal: "",
	uniqueIdentifier: GetUUID(),
};

const Nikunja = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				// 	errors.ward = "Required";
				// }
				// if (!values.name) {
				// 	errors.name = "Required";
				// }
				// if (!values.area) {
				// 	errors.area = "Required";
				// }
				// if (!values.address) {
				// 	errors.address = "Required";
				// }
				// if (!values.midArea) {
				// 	errors.midArea = "Required";
				// }
				// if (!values.area) {
				// 	errors.area = "Required";
				// }
				// if (!values.owner) {
				// 	errors.owner = "Required";
				// }
				// if (!values.mainAnimal) {
				// 	errors.mainAnimal = "Required";
				// } else if (
				// 	!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				// ) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											<option>कुनै छान्नुहोस</option>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{owner[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={owner[selectedLanguage]}
											name="owner"
											onChange={handleChange}
											value={values.owner}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="owner"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{" "}
											{mainAnimalQn[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="mainAnimal"
											onChange={handleChange}
											value={values.mainAnimal}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="mainAnimal"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									<div className="col-md-3">
										<label className="form-control-label">
											{midArea[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={midArea[selectedLanguage]}
											name="midArea"
											onChange={handleChange}
											value={values.midArea}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="midArea"
											component="div"
										/>
									</div>
									{/* <div className="col-md-3">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="midAreaType"
											name="midAreaType"
											onChange={handleChange}
											value={values.midAreaType}
										>
											{areaList.map((alist) => (
												<option value={alist.value}>{alist.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="midAreaType"
											component="div"
										/>
									</div> */}
									<div className="col-md-3">
										<label className="form-control-label">
											{area[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={area[selectedLanguage]}
											name="area"
											onChange={handleChange}
											value={values.area}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="area"
											component="div"
										/>
									</div>
									{/* <div className="col-md-3">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="areaType"
											name="areaType"
											onChange={handleChange}
											value={values.areaType}
										>
											{areaList.map((alist) => (
												<option value={alist.value}>{alist.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="areaType"
											component="div"
										/>
									</div> */}
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Nikunja;
