import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	ward,
	add,
	cancel,
	photo,
	wardQuestion,
	previousYearPregnant,
	ttFirstPregnant,
	ttSecondPregnant,
	fourTestPregnant,
	maternityHealthPost,
	maternityProfessional,
	maternityWithoutProfessional,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	previousYearPregnant: "",
	ttFirstPregnant: "",
	ttSecondPregnant: "",
	fourTestPregnant: "",
	maternityHealthPost: "",
	maternityProfessional: "",
	maternityWithoutProfessional: "",
	uniqueIdentifier: GetUUID(),
};

const SafeMaternity = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.previousYearPregnant) {
				//     errors.previousYearPregnant = 'Required';
				// }
				// if (!values.ttFirstPregnant) {
				//     errors.ttFirstPregnant = 'Required';
				// }
				// if (!values.ttSecondPregnant) {
				//     errors.ttSecondPregnant = 'Required';
				// }
				// if (!values.fourTestPregnant) {
				//     errors.fourTestPregnant = 'Required';
				// }
				// if (!values.maternityHealthPost) {
				//     errors.maternityHealthPost = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.maternityProfessional) {
				//     errors.maternityProfessional = 'Required';
				// }
				// if (!values.maternityWithoutProfessional) {
				//     errors.maternityWithoutProfessional = 'Required';
				// }
				// else
				// if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward ? values.ward : "selectOne"}
										>
											<option value="selectOne" disabled>
												कुनै एक छान्नुहोस्
											</option>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{previousYearPregnant[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={previousYearPregnant[selectedLanguage]}
											name="previousYearPregnant"
											onChange={handleChange}
											value={values.previousYearPregnant}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="previousYearPregnant"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>टि.टि. खोप लिने गर्भवती</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{ttFirstPregnant[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={ttFirstPregnant[selectedLanguage]}
											name="ttFirstPregnant"
											onChange={handleChange}
											value={values.ttFirstPregnant}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ttFirstPregnant"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{ttSecondPregnant[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={ttSecondPregnant[selectedLanguage]}
											name="ttSecondPregnant"
											onChange={handleChange}
											value={values.ttSecondPregnant}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ttSecondPregnant"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{fourTestPregnant[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={fourTestPregnant[selectedLanguage]}
											name="fourTestPregnant"
											onChange={handleChange}
											value={values.fourTestPregnant}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="fourTestPregnant"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>प्रसूति भएको आधारमा सुत्केरी संख्या</h3>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{maternityHealthPost[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={maternityHealthPost[selectedLanguage]}
											name="maternityHealthPost"
											onChange={handleChange}
											value={values.maternityHealthPost}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="maternityHealthPost"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{maternityProfessional[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={maternityProfessional[selectedLanguage]}
											name="maternityProfessional"
											onChange={handleChange}
											value={values.maternityProfessional}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="maternityProfessional"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{maternityWithoutProfessional[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={
												maternityWithoutProfessional[selectedLanguage]
											}
											name="maternityWithoutProfessional"
											onChange={handleChange}
											value={values.maternityWithoutProfessional}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="maternityWithoutProfessional"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default SafeMaternity;
